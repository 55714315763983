import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";

async function closeInteraction(host) {
  const transactionObject = await host.getObject("transaction");
  transactionObject.close();
}

function VerificationStarted({ data }) {
  const [loading, setLoading] = useState(true);

  const { host } = data;
  let isRefreshTransaction = false;
  const location = useLocation();

  ({ isRefreshTransaction } = location.state);

  useEffect(() => {
    const initPending = async () => {
      setLoading(false);
    };
    initPending();
  }, []);

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div
          id="verification-result-container"
          className="pageContainer result"
          style={{ marginBottom: "200px" }}
        >
          <div className="sectionContainer">
            <div className="verificationHeaderContainer">
              {isRefreshTransaction ? (
                <p className="verificationContentHeaderStartedPage">
                  Re-verification request received. Once the report is updated
                  you will get a report ready email.
                </p>
              ) : (
                <p className="verificationContentHeaderStartedPage">
                  Order received. We have sent the borrower an information
                  request email.
                </p>
              )}

              <button
                id="verificationButton"
                onClick={() => closeInteraction(host)}
                style={{ marginTop: "30px" }}
              >
                Back to Encompass
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VerificationStarted;
