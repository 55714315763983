import { MouseEventHandler, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import capitalize from 'lodash/capitalize';
import Typography from '@material-ui/core/Typography';
import {
  Modal,
  ModalButtons,
  ModalContent,
  ModalProps,
  ModalTitle
} from 'shared/ui/modal';
import Button from 'shared/ui/button';
import TextField from 'shared/ui/textfield';
import { Partner, PartnerCredentials } from 'api/partners/types';

interface AddPartnerCredentialsModalProps extends ModalProps {
  partners: Partner[];
  onAdd: (credentials: PartnerCredentials) => Promise<unknown>;
}

const defaultValues: Partial<PartnerCredentials> = {
  password: '',
  credentials: {
    key: '',
    value: ''
  }
};


export function AddPartnerCredentialsModal({
  open,
  onClose,
  onAdd,
  partners
}: AddPartnerCredentialsModalProps) {
  const partnersSelectOptions = partners
    .filter((p) => !p.hasCredentials)
    .map((partner) => ({
      label: capitalize(partner.partner),
      value: partner.partner
    }));

  const closeModal: MouseEventHandler<HTMLButtonElement> = (e) =>
    onClose?.(e, 'backdropClick');

  const defaultPartner = partners.filter((p) => !p.hasCredentials)[0]?.partner;
  const {
    register,
    formState: { errors, isSubmitting, isValid, isValidating },
    reset
  } = useForm({
    defaultValues: {
      ...defaultValues,
      partner: defaultPartner
    },
    mode: 'all'
  });

  useEffect(() => {
    if (!open) {
      reset({
        ...defaultValues,
        partner: defaultPartner
      });
    }
  }, [defaultPartner, reset, open]);

  const isAddButtonDisabled = isSubmitting || isValidating || !isValid;


  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
      disableBackdropClick={isSubmitting}
    >
      <form>
        <ModalTitle onClose={isSubmitting ? undefined : onClose}>
          Add Partner Credentials
        </ModalTitle>
        <ModalContent>
          <Typography variant="body2" style={{ paddingBottom: 26 }}>
            Some partners require you to enter your contract information.
            Partners added using your credentials will be identified with next
            to their names.
          </Typography>
          <TextField
            fullWidth
            label={'Select your Partner'}
            select
            defaultValue={defaultPartner}
            options={partnersSelectOptions}
            inputProps={{
              ...register('partner')
            }}
          />
          <TextField
            fullWidth
            label={'Password'}
            type="password"
            autoComplete="new-password"
            inputProps={{
              ...register('password', {
                required: 'Required'
              })
            }}
            placeholder="Set a password"
            error={!!errors.password?.message}
            helperText={errors.password?.message}
          />
          <TextField
            fullWidth
            label={'Key'}
            placeholder="Enter your credential key"
            inputProps={{
              ...register('credentials.key', {
                required: 'Required'
              })
            }}
            error={!!errors.credentials?.key?.message}
            helperText={errors.credentials?.key?.message}
          />
          <TextField
            fullWidth
            label={'Value'}
            placeholder="Enter your credential value"
            inputProps={{
              ...register('credentials.value', {
                required: 'Required'
              })
            }}
            error={!!errors.credentials?.value?.message}
            helperText={errors.credentials?.value?.message}
          />
        </ModalContent>

        <ModalButtons justifyContent="flex-end">
          <Button
            color="secondary"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isAddButtonDisabled}
            isLoading={isSubmitting}
          >
            Add Credential
          </Button>
        </ModalButtons>
      </form>
    </Modal>
  );
}
