import React, { useEffect, useState } from "react";
import _, { forEach } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import {
  createTransaction,
  initializeOriginationContext,
} from "../service/encompassService";
import { checkRequiredFieldsForAsset } from "../util/requiredFieldsForAsset";
import { create_health_metric } from "../service/healthService";
import { getPartners } from "../service/staircaseService";
import ArrowRight from "../images/icons/arrow_right.png";
import monitoringDurations from "../data/asset/equifax/monitoringDuration.json";
import { subtractDays, addDays, validateEmail } from "util/helper";
import "../App.css";
import { useHistory } from "react-router-dom";
import {
  getLoanTransactions,
  getTransactionInfo,
  getStaircaseCollection,
} from "../service/staircaseService";

let verificationType = "asset";

function AssetVerificationStartPage({ data }) {
  let transactionRequests = [];
  const history = useHistory();
  const { host } = data;

  const [encompassOriginResult, setEncompassOriginResult] = useState([]);
  const [originationContext, setOriginationContext] = useState([]);
  const [transactionObject, setTransactionObject] = useState([]);

  const [loading, setLoading] = useState(true);
  const [verifButtonClicked, setVerifButtonClicked] = useState(false);
  const [isBorrowerEnabled, setIsBorrowerEnabled] = useState([]);
  const [isCoborrowerEnabled, setIsCoborrowerEnabled] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("equifax");
  const [selectedMonitoringDuration, setSelectedMonitoringDuration] =
    useState("2M");
  const [verificationTypeLabel, setVerificationTypeLabel] =
    useState("Asset Verification");

  const [selectedPermissiblePurpose, setSelectedPermissiblePurpose] =
    useState("PPCREDIT");
  const [orderStartDate, setOrderStartDate] = useState(null);
  const [orderEndDate, setOrderEndDate] = useState(null);
  const [ccEmail, setCcEmail] = useState(null);
  const [isCcEmailValid, setIsCcEmailValid] = useState(true);
  const [isAdditionalLenderChecked, setIsAdditionalLenderChecked] =
    useState(false);
  const [isAutoImportEnabled, setIsAutoImportEnabled] = useState(false);
  const [isLoanDataValid, setIsLoanDataValid] = useState(false);

  useEffect(() => {
    const initVerification = async () => {
      let initResult;
      initResult = await initializeOriginationContext(host);
      setEncompassOriginResult(initResult.encompassOriginResult);
      setOriginationContext(initResult.originationContext);
      setTransactionObject(initResult.transactionObject);
      const domain = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.domain",
        ""
      );
      const apiKey = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.api_key",
        ""
      );
      let transaction_id = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "encompassOriginData.transaction_id",
        ""
      );
      let apps = _.get(
        initResult.encompassOriginResult,
        "encompassOriginData.response_payload.loan.applications"
      );

      let borrowerEnabledStart = [];
      for (let app_index in apps) {
        borrowerEnabledStart[app_index] = false;
      }
      setIsBorrowerEnabled(borrowerEnabledStart);
      setIsCoborrowerEnabled(borrowerEnabledStart);
      updateVerificationType(
        initResult.encompassOriginResult.encompassOriginData
      );
      updatePartners(domain, apiKey);
      setLoading(false);
      checkLoanDataValid(initResult.encompassOriginResult.encompassOriginData);
      await create_health_metric(
        domain,
        apiKey,
        "STARTED",
        "",
        "",
        "logging",
        "Opened the Encompass UI page, page successfully loaded",
        transaction_id,
        "/"
      );
    };
    initVerification().catch((error) => {
      history.push({
        pathname: "/error",
        state: {
          error: error.message,
          domain: domain,
          apiKey: apiKey,
        },
      });
    });
  }, []);

  let { encompassOriginData } = encompassOriginResult;

  const domain = _.get(
    encompassOriginData,
    "response_payload.credentials.domain",
    ""
  );
  const apiKey = _.get(
    encompassOriginData,
    "response_payload.credentials.api_key",
    ""
  );

  if (originationContext && originationContext.transactionId) {
    history.push({
      pathname: "/asset/result/single",
      state: {
        transactionRequests: [
          {
            transactionId: originationContext.transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  }

  const transactionHistoryClicked = async function () {
    console.log("transaction history clicked");

    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests: [
          {
            transactionId: originationContext.transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  const enterCredentialsClicked = async function () {
    console.log("enter credentials clicked");

    history.push({
      pathname: "/credentials",
      state: {
        domain,
        apiKey
      },
    });
  };

  const verificationButtonClicked = async function () {
    setVerifButtonClicked(true);
    //console.log(`Creating ${verificationType}`);
    //console.log("isBorrowerEnabled", isBorrowerEnabled);
    console.log(encompassOriginResult);

    const promiseArray = [];

    let entityRef = _.get(
      encompassOriginResult.encompassOriginData,
      "response_payload.entityRef.entityId",
      ""
    );

    //console.log("Entity reference number:", entityRef)

    for (let index in isBorrowerEnabled) {
      if (isBorrowerEnabled[index]) {
        let requestObject = {
          request: {
            type: verificationTypeLabel,
            options: {
              loanIndex: index,
              borrowerType: "borrower",
              partner_name: selectedPartner,
            },
          },
        };
        if (selectedPartner === "equifax") {
          let monitoringDuration = selectedMonitoringDuration;
          requestObject.request.options.isAutoImportEnabled =
            isAutoImportEnabled;
          if (selectedMonitoringDuration === "2MC") {
            monitoringDuration = "2M";
          }
          let additionalInformation = [];
          additionalInformation.push({
            key: "permissiblePurpose",
            value: selectedPermissiblePurpose,
          });
          additionalInformation.push({
            key: "monitoringDuration",
            value: monitoringDuration,
          });
          if (isAdditionalLenderChecked && ccEmail) {
            additionalInformation.push({
              key: "ccEmail",
              value: ccEmail,
            });
          }

          if (selectedMonitoringDuration === "2MC") {
            if (orderStartDate) {
              additionalInformation.push({
                key: "orderStartDate",
                value: orderStartDate.toISOString().slice(0, 10),
              });
            }
            if (orderEndDate) {
              additionalInformation.push({
                key: "orderEndDate",
                value: orderEndDate.toISOString().slice(0, 10),
              });
            }
          }
          requestObject.request.options.additionalInformation =
            additionalInformation;
        }
        promiseArray.push(
          createTransaction(
            requestObject,
            transactionObject,
            encompassOriginResult
          )
        );
        await new Promise((r) => setTimeout(r, 200));
      }
    }

    for (let index in isCoborrowerEnabled) {
      if (isCoborrowerEnabled[index]) {
        let requestObject = {
          request: {
            type: verificationTypeLabel,
            options: {
              loanIndex: index,
              borrowerType: "coborrower",
              partner_name: selectedPartner,
            },
          },
        };
        if (selectedPartner === "equifax") {
          requestObject.request.options.isAutoImportEnabled =
            isAutoImportEnabled;
          let monitoringDuration = selectedMonitoringDuration;
          if (selectedMonitoringDuration === "2MC") {
            monitoringDuration = "2M";
          }
          let additionalInformation = [];
          additionalInformation.push({
            key: "permissiblePurpose",
            value: selectedPermissiblePurpose,
          });
          additionalInformation.push({
            key: "monitoringDuration",
            value: monitoringDuration,
          });

          if (isAdditionalLenderChecked && ccEmail) {
            additionalInformation.push({
              key: "ccEmail",
              value: ccEmail,
            });
          }

          if (selectedMonitoringDuration === "2MC") {
            if (orderStartDate) {
              additionalInformation.push({
                key: "orderStartDate",
                value: orderStartDate.toISOString().slice(0, 10),
              });
            }
            if (orderEndDate) {
              additionalInformation.push({
                key: "orderEndDate",
                value: orderEndDate.toISOString().slice(0, 10),
              });
            }
          }
          requestObject.request.options.additionalInformation =
            additionalInformation;
        }
        promiseArray.push(
          createTransaction(
            requestObject,
            transactionObject,
            encompassOriginResult
          )
        );
        await new Promise((r) => setTimeout(r, 200));
      }
    }

    transactionRequests = await Promise.all(promiseArray);
    console.log(transactionRequests);
    await create_health_metric(
      domain,
      apiKey,
      "STARTED",
      "",
      "",
      "logging",
      "Ordered the verification",
      "",
      "/"
    );

    // Load transaction history page

    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests,
        domain,
        apiKey,
        verificationType,
        isRefreshTransaction: false
      },
    });

    /* if (transactionRequests.length > 1) {
      history.push({
        pathname: "/transactions",
        state: {
          transactionRequests,
          domain,
          apiKey,
          verificationType,
          isRefreshTransaction: false
        },
      });
    } else {
      history.push({
        pathname: "/asset/result/single",
        state: {
          transactionRequests,
          domain,
          apiKey,
          verificationType,
          isRefreshTransaction: false
        },
      });
    } */
  };

  async function getDetails(transaction) {
    let trRes = await getTransactionInfo(
      transaction.id,
      domain,
      apiKey,
      "asset"
    );
    return trRes;
  }

  async function getCollections(detail) {
    if (detail) {
      if (
        detail.response_payload.response &&
        detail.response_payload.response.referenceNumber
      ) {
        const referenceNumbers =
          detail.response_payload?.response?.referenceNumber.split("#");
        if (referenceNumbers.length >= 2) {
          const transactionId = referenceNumbers[0];
          const collectionId = referenceNumbers[1];
          return await getStaircaseCollection(
            domain,
            apiKey,
            transactionId,
            collectionId
          );
        } else {
          return { response_payload: { response: { status: "processing" } } };
        }
      }
    } else {
      return { response_payload: { response: { status: "processing" } } };
    }
  }

  function checkLoanDataValid(encompassOriginData) {
    if (encompassOriginData && encompassOriginData.response_payload) {
      let applications = _.get(
        encompassOriginData,
        "response_payload.loan.applications"
      );
      if (applications) {
        applications.map((value, index) => {
          const validationResponse = checkRequiredFieldsForAsset(
            encompassOriginData,
            index
          );

          const missingFields = validationResponse.missingFields;
          const invalidFields = validationResponse.invalidFields;

          if (missingFields.length === 0 && invalidFields.length === 0) {
            setIsLoanDataValid(true);
            return true;
          }

          setIsLoanDataValid(false);
          return false;
        });
      }
    }
  }

  function checkVerificationButtonEnabled() {
    let isOnePersonSelected = false;

    if (isAdditionalLenderChecked && ccEmail && !validateEmail(ccEmail)) {
      return false;
    }

    _.each(isBorrowerEnabled, (enabled) => {
      if (enabled) {
        isOnePersonSelected = true;
      }
    });

    _.each(isCoborrowerEnabled, (enabled) => {
      if (enabled) {
        isOnePersonSelected = true;
      }
    });

    if (isOnePersonSelected && selectedPartner !== "equifax") {
      return true;
    }

    if (isOnePersonSelected && selectedMonitoringDuration === "2M") {
      return true;
    }

    if (isOnePersonSelected && selectedMonitoringDuration === "12M") {
      return true;
    }

    if (
      isOnePersonSelected &&
      selectedMonitoringDuration === "2MC" &&
      orderStartDate &&
      orderEndDate
    ) {
      return true;
    }

    return false;
  }

  function checkBorrowerEnabled(e, index) {
    setIsBorrowerEnabled((prevState) => {
      let newEnabled = [...prevState];
      newEnabled[index] = e.target.checked;
      return newEnabled;
    });
  }

  function checkCoborrowerEnabled(e, index) {
    setIsCoborrowerEnabled((prevState) => {
      let newEnabled = [...prevState];
      newEnabled[index] = e.target.checked;
      return newEnabled;
    });
  }

  async function updateVerificationType(encompassOriginData) {
    const productName = _.get(
      encompassOriginData,
      "response_payload.product.name",
      ""
    )
      .replace(/\s/g, "")
      .toLowerCase();

    if (
      productName.startsWith("staircaseincome") ||
      productName.startsWith("staircasevoi")
    ) {
      setVerificationTypeLabel("Income Verification");
      verificationType = "income";
    } else if (
      productName.startsWith("staircaseasset") ||
      productName.startsWith("staircasevoa")
    ) {
      setVerificationTypeLabel("Asset Verification");
      verificationType = "asset";
    } else if (
      productName.startsWith("staircaseemployment") ||
      productName.startsWith("staircasevoe")
    ) {
      setVerificationTypeLabel("Employment Verification");
      verificationType = "employment";
    }
    console.log("productName", productName);
    console.log("verificationType", verificationType);
    console.log("verificationTypeLabel", verificationTypeLabel);
  }

  async function updatePartners(domain, apiKey) {
    if (verificationType === "asset") {
      const asset_partners = [
        {
          byoc: false,
          active: true,
          status: "active",
          partner: "equifax",
          details: { description: "Equifax" },
          order: 1,
          partnerTitle: "Equifax",
          disabled: "",
        },
        {
          byoc: false,
          active: true,
          status: "active",
          partner: "finicity",
          details: { description: "Finicity , https://www.finicity.com/" },
          order: 2,
          partnerTitle: "Finicity",
          disabled: "",
        },
        {
          byoc: false,
          active: true,
          status: "active",
          partner: "plaid",
          details: { description: "Plaid , https://plaid.com/" },
          order: 8,
          partnerTitle: "Plaid",
          disabled: "",
        },
      ];
      //console.log(asset_partners);
      setPartners(asset_partners);
      return;
    }
    let partners = await getPartners(domain, apiKey, verificationType);
    for (let p in partners) {
      partners[p]["partnerTitle"] =
        partners[p]["partner"].charAt(0).toUpperCase() +
        partners[p]["partner"].slice(1);
      if (partners[p]["active"] !== true) partners[p]["disabled"] = "disabled";
      else partners[p]["disabled"] = "";
    }
    //console.log(partners);
    setPartners(partners);
  }

  function checkMissingFields(encompassOriginData, index) {
    if (encompassOriginData && encompassOriginData.response_payload) {
      const validationResponse = checkRequiredFieldsForAsset(
        encompassOriginData,
        index
      );

      const missingFields = validationResponse.missingFields;
      const invalidFields = validationResponse.invalidFields;

      if (missingFields.length === 0 && invalidFields.length === 0) {
        return null;
      }

      return (
        <div id="missing-fields-container" className="borrower missingInfo">
          {missingFields.length > 0 ? (
            <div>
              <div className="missing_info">Missing Information</div>
              <ul>
                {missingFields.map((field) => (
                  <li key={field}>
                    <span>
                      <b>{field}</b>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {invalidFields.length > 0 ? (
            <div>
              <div className="missing_info">Invalid Information</div>
              <ul>
                {invalidFields.map((field) => (
                  <li key={field}>
                    <span>
                      <b>{field}</b>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      );
    }
  }

  const applications = _.get(
    encompassOriginData,
    "response_payload.loan.applications"
  );

  const partnerChangedHandler = (event) => {
    setSelectedPartner((prevState) => {
      return event.target.value;
    });
  };

  const monitoringDurationChangedHandler = (event) => {
    if (event.target.value === "12M") {
      setOrderStartDate(null);
      setOrderEndDate(null);
    }
    setSelectedMonitoringDuration((prevState) => {
      return event.target.value;
    });
  };

  const ccEmailOnChangeHandler = (event) => {
    const ccEmail = event.target.value;
    setCcEmail(ccEmail);
    if (!ccEmail) {
      setIsCcEmailValid(true);
    } else {
      setIsCcEmailValid(validateEmail(ccEmail));
    }
  };

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div id="verificationContainer" className="pageContainer">
          <div className="verificationHeaderHolder">
            <p id="verificationHeader" className="verificationContentHeader">
              New Assets Verification
            </p>
          </div>
          <div className="sectionContainer-start">
            <div>
              <p className="verificationContainerSubHeader">
                Verify Borrower Assets
              </p>
            </div>
            <div className="partnerInfoHolder">
              <div className="labelHolder">
                <p className="selectLabel">Select your verification partner</p>
              </div>
              <div className="partnerSelectHolder">
                <select
                  name="partnersSelect"
                  id="partnersSelect"
                  className="partnersSelect"
                  onChange={partnerChangedHandler}
                >
                  {verificationType !== "asset" ? (
                    <option value="default">Waterfall</option>
                  ) : null}

                  {partners
                    .filter(function (p) {
                      return !p["disabled"];
                    })
                    .map((value, key) => (
                      <option
                        className="partnersSelectOption"
                        value={value["partner"]}
                      >
                        {value["partnerTitle"]}
                      </option>
                    ))}
                </select>
                {selectedPartner === "equifax" ? (
                <button
                  onClick={enterCredentialsClicked}
                  className="transactionHistoryButton" 
                >
                  <div className="transactionHistoryLabel">
                    Enter Credentials
                  </div>
                </button>
                ): null}
                <button
                  onClick={transactionHistoryClicked}
                  className="transactionHistoryButton"
                >
                  <div className="transactionHistoryLabel">
                    Transaction History
                  </div>
                </button>
              </div>
            </div>
            <div className="BorrowerInfoHolder">
              <div className="autoImportHolder">
                <p>Select a borrower for verification</p>
                {selectedPartner === "equifax" ? (
                  <div className="autoImportCheckboxHolder">
                    <div className="autoImportCheckBox">
                      Enable auto import accounts
                    </div>
                    <input
                      type="checkbox"
                      className="checkbox-auto-import"
                      onClick={(e) => setIsAutoImportEnabled(e.target.checked)}
                    />
                  </div>
                ) : null}
              </div>

              {applications.map((value, index) => {
                let input_id = `checkbox-borrower-${index}`;
                let input_id_co = `checkbox-coborrower-${index}`;
                return (
                  <div className="borrower_coborrower">
                    <div className="borrower top">
                      <div>
                        <div className="verification-row-borrower-1-20">
                          <div className="verification-block-1-1">
                            <input
                              type="checkbox"
                              className="checkbox-borrower"
                              id={input_id}
                              onClick={(e) => checkBorrowerEnabled(e, index)}
                            />
                          </div>
                          <div className="borrowerName">
                            <div className="boldedLabel">Borrower: </div>
                            {value["borrower"]["firstName"].length > 0 &&
                              value["borrower"]["lastName"].length > 0 ? (
                              <div className="simplelabel">
                                {value["borrower"]["firstName"]}{" "}
                                {value["borrower"]["lastName"]}
                              </div>
                            ) : (
                              <div className="simplelabel missing">Missing</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {value["coborrower"]["firstName"] != null ? (
                      <div
                        id="coborowwerVerificationContainer"
                        className="borrower coborrower"
                      >
                        {value["coborrower"]["firstName"] ||
                          value["coborrower"]["lastName"] ? (
                          <div>
                            <div className="verification-row-coborrower-1-20">
                              <div className="verification-block-1-1">
                                <input
                                  type="checkbox"
                                  className="checkbox-borrower"
                                  id={input_id_co}
                                  onClick={(e) =>
                                    checkCoborrowerEnabled(e, index)
                                  }
                                />
                              </div>
                              <div className="borrowerName">
                                <div className="boldedLabel">Co-Borrower: </div>
                                {value["coborrower"]["firstName"].length > 0 &&
                                  value["coborrower"]["lastName"].length > 0 ? (
                                  <div className="simplelabel">
                                    {value["coborrower"]["firstName"]}{" "}
                                    {value["coborrower"]["lastName"]}
                                  </div>
                                ) : (
                                  <div className="simplelabel missing">
                                    Missing
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {checkMissingFields(encompassOriginData, index)}
                  </div>
                );
              })}
            </div>
            {selectedPartner === "equifax" ? (
              <div className="permissiblePurposeAndMonitoringDurationSelectHolder">
                <div>
                  <p>
                    Select Verification Timeframe{" "}
                    <span className="pSpan">(months of statements)</span>
                  </p>
                  <select
                    name="monitoringDurationSelect"
                    id="monitoringDurationSelect"
                    className="monitoringDurationSelect"
                    onChange={monitoringDurationChangedHandler}
                  >
                    {monitoringDurations.data.map((value, key) => (
                      <option
                        className="monitoringDurationSelectOption"
                        value={value["value"]}
                      >
                        {value["description"]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}

            {selectedPartner === "equifax" &&
              selectedMonitoringDuration === "2MC" ? (
              <div className="timePeriodInputMainHolder">
                <div className="timePeriodInputHolder">
                  <div>
                    <p>
                      Time period start date{" "}
                      <span className="pSpan">(between 30-60 days ago)</span>
                    </p>
                    <DatePicker
                      selected={orderStartDate}
                      onChange={(date) => setOrderStartDate(date)}
                      openToDate={subtractDays(30, new Date())}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      includeDateIntervals={[
                        {
                          start: subtractDays(60, new Date()),
                          end: subtractDays(30, new Date()),
                        },
                      ]}
                      placeholderText="MM/DD/YYYY"
                    />
                  </div>
                  <div>
                    <p>Time period end date</p>
                    {orderStartDate ? (
                      <DatePicker
                        selected={orderEndDate}
                        onChange={(date) => setOrderEndDate(date)}
                        openToDate={addDays(30, orderStartDate)}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        includeDateIntervals={[
                          {
                            start: addDays(30, orderStartDate),
                            end: new Date(),
                          },
                        ]}
                      />
                    ) : (
                      <DatePicker disabled placeholderText="MM/DD/YYYY" />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {selectedPartner === "equifax" ? (
              <div className="emailHolder">
                <div>
                  <div className="additionalLenderContainer">
                    <input
                      type="checkbox"
                      className="checkbox-additional-lender"
                      onClick={(e) =>
                        setIsAdditionalLenderChecked(e.target.checked)
                      }
                    />
                    <div className="additionalLenderCheckBox">
                      I would like copies of consumer email notifications to be
                      sent to another email address associated with my lender
                      organization
                    </div>
                  </div>

                  {isAdditionalLenderChecked ? (
                    <div>
                      <p>Additional Lender's Email</p>
                      <input
                        type="email"
                        className={
                          isCcEmailValid
                            ? "additionalLendersEmailInput"
                            : "additionalLendersEmailInputNotValid"
                        }
                        onChange={(event) => ccEmailOnChangeHandler(event)}
                      />
                      <div className="additionalLendersEmailInputDefinition">
                        By submitting a lender email address, you certify that
                        the owner of this email address is associated with your
                        organization and is authorized to receive notifications
                        regarding this order. You further understand and
                        acknowledge that the owner of this email address will
                        not be able to access any documentation relating to this
                        order if they are not appropriately set up as an
                        authorized user.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <div id="buttons_holder" className="sectionContainer-start">
            <button
              id="verificationButton"
              onClick={verificationButtonClicked}
              disabled={
                !isLoanDataValid ||
                !checkVerificationButtonEnabled() ||
                verifButtonClicked
              }
            >
              Start Verification
              {verifButtonClicked === true ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                  id="loader"
                  style={{
                    position: "relative",
                    left: "5%",
                  }}
                />
              ) : (
                <div className="nextArrowHolder">
                  <img src={ArrowRight} className="nextArrowIcon" />
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default AssetVerificationStartPage;
