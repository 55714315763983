import identifiers from "../data/asset/productApiIdentifiers.json";

const product_name = "LOS";
let service_endpoint = "/encompass-service-application-ui";
let transaction_id = "encompass-ui-service-application-transaction";
const ASSET_PRODUCT_IDENTIFIER = "48597cc5-c7d6-4856-9243-6fb4819d96e0";

async function create_health_metric(
  host_environment,
  api_key,
  operation_status,
  health_data,
  error_log,
  metric_type,
  logging_message,
  _transaction_id,
  _service_endpoint
) {
  try {
    if (_transaction_id) transaction_id = _transaction_id;
    if (_service_endpoint) service_endpoint = _service_endpoint;

    let health_record_payload = {
      host_environment: host_environment,
      product_name: product_name,
      service_endpoint: service_endpoint,
    };
    if (operation_status)
      health_record_payload["operation_status"] = operation_status;
    if (health_data) health_record_payload["data"] = health_data;
    if (error_log) health_record_payload["error"] = error_log;
    if (metric_type) health_record_payload["metric_type"] = metric_type;
    else health_record_payload["metric_type"] = "transaction";
    if (logging_message)
      health_record_payload["logging_message"] = logging_message;

    let url =
      "https://" +
      host_environment +
      "/code-health-checker/metric/" +
      transaction_id;
    let headers = { "x-api-key": api_key, "content-type": "application/json" };

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify(health_record_payload),
    });
    if (res.ok) {
      await res.json();
      console.log("HEALTH stored");
    } else {
      console.log(res);
    }
  } catch (e) {
    console.error("Error while creating health metric", e);
  }
}

async function createNewHealthMetric(domain, apiKey, params) {
  try {
    //Right now new health metric only works with asset product
    if (params.productName !== "asset" && !params.apiName) {
      return;
    }

    let requestObject = {};

    if (params.productName === "asset") {
      requestObject.product_identifier = ASSET_PRODUCT_IDENTIFIER;
    }

    requestObject.product_api_identifier =
      identifiers[params.apiName].product_api_identifier;

    if (params.transactionId) {
      requestObject.transaction_id = params.transactionId;
    } else {
      requestObject.transaction_id = transaction_id;
    }
    if (params.collectionId) {
      requestObject.response_collection_id = params.collectionId;
    } else {
      requestObject.response_collection_id = transaction_id
    }

    if (params.statusCode) {
      requestObject.invocation_code = params.statusCode;
    }
    if (params.status) {
      requestObject.status = params.status;
    }

    if (params.elapsedTime) {
      requestObject.elapsed_time = params.elapsedTime;
    } else {
      requestObject.elapsed_time = 0;
    }

    let url = "https://" + domain + "/code-health-checker/metrics/";
    let headers = { "x-api-key": apiKey, "content-type": "application/json" };

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify(requestObject),
    });
    if (res.ok) {
      await res.json();
      console.log("HEALTH stored");
    } else {
      console.log(res);
    }
  } catch (e) {
    console.error("Error while creating health metric", e);
  }
}

export { create_health_metric, createNewHealthMetric };
