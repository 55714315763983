import BackIcon from "../images/icons/ic_back.png";
import StaircaseLogo from "../images/logo_staircase.png";
import HelpIcon from "../images/icons/ic_help.png";

async function closeInteraction(host) {
  const transactionObject = await host.getObject("transaction");
  transactionObject.close();
}

function Header({ data }) {
  const { host } = data;
  return (
    <div id="topBar">
      <div id="topLeftDiv">
        <img className="back-icon" src={BackIcon} alt="Back Icon"></img>
        <p className="topBarText" onClick={() => closeInteraction(host)}>
          Back to encompass
        </p>
      </div>
      <div id="topCenterDiv">
        <img id="staircase-icon" src={StaircaseLogo} alt="Staircase Icon"></img>
      </div>
      <div id="topRightDiv">
      </div>
    </div>
  );
}

export default Header;
