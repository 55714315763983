import _ from "lodash";
import moment from "moment";
import { validateEmail } from "util/helper";

const requiredFieldList = [
  // borrower required fields
  {
    path: "response_payload.loan.applications[0].borrower.firstName",
    encompassName: "Borrower First Name",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.lastName",
    encompassName: "Borrower Last Name",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.taxIdentificationIdentifier",
    encompassName: "Borrower SSN",
    type: "string",
    borrowerType: "borrower",
  },
  {
    path: "response_payload.loan.applications[0].borrower.emailAddressText",
    encompassName: "Borrower Email Address",
    type: "string",
    borrowerType: "borrower",
    format: "email",
  },
  {
    path: "response_payload.loan.applications[0].borrower.birthDate",
    encompassName: "Borrower Birth Date",
    type: "string",
    borrowerType: "borrower",
    format: "date",
  },
  {
    path: "response_payload.loan.applications[0].borrower.residences",
    encompassName: "Borrower Address Information",
    type: "residence",
    borrowerType: "borrower",
  },
  // coborrower required fields
  {
    path: "response_payload.loan.applications[0].coborrower.firstName",
    encompassName: "Coborrower First Name",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.lastName",
    encompassName: "Coborrower Last Name",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.taxIdentificationIdentifier",
    encompassName: "Coborrower SSN",
    type: "string",
    borrowerType: "coborrower",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.emailAddressText",
    encompassName: "Coborrower Email Address",
    type: "string",
    borrowerType: "coborrower",
    format: "email",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.birthDate",
    encompassName: "Coborrower Birth Date",
    type: "string",
    borrowerType: "coborrower",
    format: "date",
  },
  {
    path: "response_payload.loan.applications[0].coborrower.residences",
    encompassName: "Coborrower Address Information",
    type: "residence",
    borrowerType: "coborrower",
  },
];

function checkRequiredFieldsForAsset(
  encompassOriginData,
  index
) {
  const fieldList = requiredFieldList;

  const regex = /\[[0-9]\]/i;
  for (let a in fieldList) {
    fieldList[a].path = fieldList[a].path.replace(regex, "[" + index + "]");
  }

  let has_coborrower = false;
  let coborrower_name =
    _.get(
      encompassOriginData,
      "response_payload.loan.applications[0].coborrower.firstName",
      ""
    ) +
    _.get(
      encompassOriginData,
      "response_payload.loan.applications[0].coborrower.lastName",
      ""
    );

  if (coborrower_name.length > 1) {
    has_coborrower = true;
  }
  const missingFields = [];
  const invalidFields = [];
  fieldList
    .filter((requiredField) => requiredField.type === "string")
    .forEach((requiredField) => {
      const field = _.get(encompassOriginData, requiredField.path, null);
      if (!field) {
        if (
          (has_coborrower && requiredField.borrowerType === "coborrower") ||
          requiredField.borrowerType === "borrower"
        )
          missingFields.push(requiredField.encompassName);
     
      } else {
        if (requiredField.format) {
          if (requiredField.format === "date") {
            if (!moment(field, "YYYY-MM-DD", true).isValid()) {
              invalidFields.push(
                `Value for ${requiredField.encompassName} is invalid: ${field}`
              );
            }
          }
          if (requiredField.format === "email") {
            if (!validateEmail(field)) {
              invalidFields.push(
                `Value for ${requiredField.encompassName} is invalid: ${field}`
              );
            }
          }
        }
      }
    });

  fieldList
    .filter((requiredField) => requiredField.type === "employment")
    .forEach((requiredField) => {
      const employmentObj = _.get(
        encompassOriginData,
        requiredField.path,
        null
      );
      if (employmentObj) {
        let employmentExist = false;
        employmentObj.forEach((employment) => {
          if (employment.employerName) {
            employmentExist = true;
          }
        });
        if (!employmentExist) {
          if (
            (has_coborrower && requiredField.borrowerType === "coborrower") ||
            requiredField.borrowerType === "borrower"
          )
            missingFields.push(requiredField.encompassName);
        }
      }
    });

  fieldList
    .filter((requiredField) => requiredField.type === "residence")
    .forEach((requiredField) => {
      const residenceObj = _.get(encompassOriginData, requiredField.path, null);
      let residenceExist = false;
      if (residenceObj) {
        residenceObj.forEach((residence) => {
          if (
            residence.addressCity &&
            residence.addressPostalCode &&
            residence.addressState &&
            residence.urla2020StreetAddress
          ) {
            residenceExist = true;
          }
        });
      }
      if (!residenceExist) {
        if (
          (has_coborrower && requiredField.borrowerType === "coborrower") ||
          requiredField.borrowerType === "borrower"
        )
          missingFields.push(requiredField.encompassName);
      }
    });

  return {
    missingFields,
    invalidFields,
  };
}

export { checkRequiredFieldsForAsset };
