function getAmericanDateFromDate(dateString) {
  const timestamp = Date.parse(dateString);
  var date = new Date(timestamp);
  return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
}

function toUpperFirstChar(str) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getUIDomain() {
  let ui_domain = "bushnell-production.staircaseapi.com";
  if (window.location.host.indexOf("bushnell.staircaseapi.com") > -1) {
    ui_domain = "bushnell.staircaseapi.com";
  }
  return ui_domain;
}

function subtractDays(numOfDays, date) {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() - numOfDays);
  return newDate;
}

function addDays(numOfDays, date) {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numOfDays);
  return newDate;
}

function validateEmail(ccEmail) {
  const regularExpressionForEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return String(ccEmail).toLowerCase().match(regularExpressionForEmail);
}

export {
  getAmericanDateFromDate,
  toUpperFirstChar,
  getUIDomain,
  subtractDays,
  addDays,
  validateEmail
};
