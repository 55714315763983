import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";

function CredentialsPage({ data }) {
  const history = useHistory();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const { domain, apiKey } = location.state;
  const { host } = data;

  const backToAsset = async function () {
    console.log("back to asset clicked");

    history.push({
      pathname: "/asset",
      state: {
        domain,
        apiKey
      },
    });
  };

  const storeCreds = async () => {
    setLoading(true);
    const creds = { "username": username, "password": password };
    try {
      const res = await fetch(
        `https://${domain}/asset/setup/equifax/credentials`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
          body: JSON.stringify(creds),
        }
      );

      const productCallInfo = await res.json();
      setMessage('Credentials registered successfully.');
      console.log("Response OK:", productCallInfo);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      console.error("Error storing credentials", error);
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  }

  const styles = {
    pageContainer: {
      fontFamily: '"Open Sans", sans-serif',
      maxWidth: '600px',
      margin: 'auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100vh',
      alignItems: 'center',
    },
    verificationHeaderHolder: {
      textAlign: 'center',
      marginBottom: '20px',
      width: '100%',
    },
    verificationContentHeader: {
      fontSize: '32px', // Increased font size for heading
      fontWeight: '700', // Bold font weight
      color: '#333',
      marginBottom: '10px',
    },
    partnerInfoHolder: {
      marginBottom: '20px',
      width: '100%',
    },
    labelHolder: {
      marginBottom: '10px',
    },
    selectLabel: {
      fontSize: '18px',
      fontWeight: '400',
      color: '#333',
      marginBottom: '5px',
      display: 'block',
    },
    partnerSelectHolder: {
      display: 'flex',
    },
    input: {
      width: '100%',
      padding: '15px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      boxSizing: 'border-box',
      fontSize: '16px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%', // Use the full width to separate buttons
    },
    cancelButton: {
      backgroundColor: '#f8f9fa',
      color: '#212529',
      padding: '12px 25px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: '500',
      fontSize: '16px',
      width: 'auto',
    },
    button: {
      backgroundColor: '#007bff',
      color: 'white',
      padding: '12px 25px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: '500',
      marginRight: '10px',
      fontSize: '16px',
      width: 'auto',
    },
    lastButton: {
      marginRight: '0',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1050, // Use a high zIndex value to ensure it's above everything else
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    closeButton: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      outline: 'none',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    modal: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '4px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1001, // Above the overlay
      textAlign: 'center',
      maxWidth: '300px',
      width: '100%'
    },
  };

  return (
    <>
      {loading && (
        <div style={styles.overlay}>
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={120}
            width={120}
            id="loader"
            style={{
              position: "absolute",
              left: "46%",
              top: "35%",
              zIndex: 1000, // Make sure it's above everything else
            }}
          />
        </div>

      )}
      {showModal && (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            
            <p>{message}</p>
            <button style={styles.closeButton} onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
      {
        <div style={styles.pageContainer}>
          <div style={styles.verificationHeaderHolder}>
            <h1 style={styles.verificationContentHeader}>Register Credentials</h1>
          </div>
          <div style={styles.partnerInfoHolder}>
            <div style={styles.labelHolder}>
              <label style={styles.selectLabel} htmlFor="username">Username</label>
              <div style={styles.partnerSelectHolder}>
                <input
                  type="text"
                  id="username"
                  placeholder="Enter username"
                  style={styles.input}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div style={styles.labelHolder}>
              <label style={styles.selectLabel} htmlFor="password">Password</label>
              <div style={styles.partnerSelectHolder}>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter password"
                  style={styles.input}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={styles.buttonContainer}>
            <button style={styles.cancelButton} onClick={backToAsset}>Cancel</button>
            <button style={{ ...styles.button, ...styles.lastButton }} onClick={storeCreds}>Register</button>
          </div>
        </div>
      }
    </>
  );
}

export default CredentialsPage;
