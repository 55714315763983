import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import { create_health_metric } from "../service/healthService";
import {
  getTransactionInfo,
  downloadReport,
  getStaircaseCollection,
  onDemandStatusCheck,
  populateData,
  retrieveLatestAccountData,
  retrieveProductFlowInvocationStatus
} from "../service/staircaseService";
import { getAmericanDateFromDate, toUpperFirstChar } from "../util/helper";
import useInterval from "../hooks/useInterval";
import {
  createTransaction,
  initializeOriginationContext,
} from "../service/encompassService";
import { useHistory } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function AssetVerificationResultPage({ data }) {
  console.log("AssetVerificationResultPage");
  let domain, apiKey, verificationType, transactionRequests;
  const location = useLocation();
  const { host } = data;
  const history = useHistory();

  if (process.env.NODE_ENV === "development") {
    domain = "russell-dev.staircaseapi.com";
    apiKey = "73884068-3362-4899-b7db-4c72e0ac46b2";
    verificationType = "Asset";
    transactionRequests = [
      { transactionId: "4ff24c07-5ac3-43f9-b73a-7b3a3c2fc0c7" },
    ];
  } else {
    ({ transactionRequests, domain, apiKey, verificationType } =
      location.state);
  }
  const [transactionsResult, setTransactionsResult] = useState({});
  const [collectionResponse, setCollectionResponse] = useState(null);
  const [verifButtonClicked, setVerifButtonClicked] = useState(false);

  const [viewDocumentButton, setViewDocumentButton] = useState(false);
  const [refreshButtonClicked, setRefreshButtonClicked] = useState(false);
  const [importButtonClicked, setImportButtonClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [borrowerType, setBorrowerType] = useState("borrower");
  const [loanIndex, setLoanIndex] = useState(0);
  const [status, setStatus] = useState("Processing");
  const [partnerName, setPartnerName] = useState("equifax");
  const [verificationResult, setVerificationResult] = useState(null);
  const [partnerReferenceNumber, setPartnerReferenceNumber] = useState("-");
  const [latestTransactionId, setLatestTransactionId] = useState("");
  const [latestResponseCollectionId, setLatestResponseCollectionId] = useState("");
  const [latestAccountInfoMessage, setLatestAccountInfoMessage] = useState("");
  const [latestAccountInfoMessageColor, setLatestAccountInfoMessageColor] = useState("#000000");

  let isCompleted = false
  let tempReferenceNumber = ""
  let tempConsumerIdentifierLast4 = ""
  let tempCollection = ""
  let latest_transaction_id = ""
  let latest_response_collection_id = ""

  useEffect(() => {
    initPending();
  }, []);

  const initPending = async () => {
    const tr = transactionRequests[0];

    if (process.env.NODE_ENV !== "development") {
      try {
        const transactionObject = await host.getObject("transaction");
        await transactionObject.set({ id: tr.transactionId });
      } catch (e) {
        console.log("Could not set transaction id to the current context.");
      }
    }

    const res = await getTransactionInfo(
      tr.transactionId,
      domain,
      apiKey,
      "asset"
    );

    const referenceNumber = _.get(
      res,
      "response_payload.response.referenceNumber",
      null
    );

    await create_health_metric(
      domain,
      apiKey,
      "STARTED",
      "",
      "",
      "logging",
      `Opening the Asset Verification Result Page with reference number:${referenceNumber} `,
      tr.transactionId,
      "/"
    );

    //console.log("get_latest_data - new code")

    if (referenceNumber) {
      await getReferenceResult(res, referenceNumber);
    }

    try {

      if (isCompleted) {

        // local testing
        // let invocation_id = await retrieveLatestAccountData(domain, apiKey, 999551680516, 1234)
        let invocation_id = await retrieveLatestAccountData(domain, apiKey, tempReferenceNumber, tempConsumerIdentifierLast4)
        let invocation_status = ""
        let invocation_response = ""
        let latest_accounts = ""

        do {

          await new Promise((r) => setTimeout(r, 3000));
          invocation_response = await retrieveProductFlowInvocationStatus(domain, apiKey, invocation_id)
          try {
            invocation_status = invocation_response["invocation_status"]
          } catch (error) {
            invocation_status = invocation_response
          }
          //console.log("get_latest_data - invocation status", invocation_status)

        } while (invocation_status == "STARTED" || invocation_status == "RUNNING");

        if (invocation_status == "COMPLETED") {
          //console.log("get_latest_data - invocation status", invocation_status)
          //console.log("get_latest_data - collection response", tempCollection)
          latest_accounts = invocation_response["response_collection"]["data"]["assets"]
          tempCollection["data"]["assets"] = latest_accounts
          setCollectionResponse(tempCollection)
          latest_transaction_id = invocation_response["transaction_id"]
          latest_response_collection_id = invocation_response["response_collection_id"]
          setLatestTransactionId(latest_transaction_id)
          setLatestResponseCollectionId(latest_response_collection_id)
          setLatestAccountInfoMessageColor("#1E9C0A")
          setLatestAccountInfoMessage("Successfully updated accounts data with the latest information.")
        } else {
          //console.log("get_latest_data - invocation status", invocation_status)
          setLatestAccountInfoMessageColor("#D5320F")
          setLatestAccountInfoMessage("Failed to update accounts data with the latest information.")
        }
      }

    } catch (e) {
      //console.log("get_latest_data - Catch error after retrieval", e)
      setLatestAccountInfoMessageColor("#D5320F")
      setLatestAccountInfoMessage("Failed to update accounts data with the latest information.")
    }

    setPartnerName(
      _.get(res, "response_payload.request.options.partner_name")
    );
    setBorrowerType(
      _.get(res, "response_payload.request.options.borrowerType")
    );
    setLoanIndex(
      parseInt(_.get(res, "response_payload.request.options.loanIndex", 0))
    );
    setTransactionsResult({ ...res });
    setLoading(false);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Refresh</strong> balances on initial VOA request
      </Popover.Body>
    </Popover>
  );

  // Document preview
  async function openModal(resource) {
    try {
      //console.log("document-preview openModal function start")
      const applicationObject = await host.getObject('application')
      //console.log("document-preview applicationObject", applicationObject)
      //console.log("document-preview resource", resource)
      const success = await applicationObject.openModal(resource)
      //console.log("document-preview success", success)
      return true
    } catch (error) {
      //console.log("document-preview catch block")
      //console.log({ error })
      return false
    }
  }
  async function getReferenceResult(trResult, referenceNumber) {
    const refArr = referenceNumber.split("#");
    const staircaseTransactionId = refArr[0];
    const staircaseCollectionId = refArr[1];

    if (staircaseCollectionId) {
      let collection = await getStaircaseCollection(
        domain,
        apiKey,
        staircaseTransactionId,
        staircaseCollectionId,
        "asset"
      );

      //console.log("get_latest_data - old collection", collection)

      // Related to retrieve latest account data START
      try {
        if (collection["metadata"]["invocation_status"] == "COMPLETED") {
          //console.log("get_latest_data", "This is completed verification.")
          isCompleted = true
          tempReferenceNumber = _.get(collection, "metadata.partner_reference_number", "")
          //console.log("get_latest_data - Reference number", tempReferenceNumber)
          tempConsumerIdentifierLast4 = _.get(collection, "data.people[0].has_taxpayer_identifier_value.has_value", "")
          tempConsumerIdentifierLast4 = tempConsumerIdentifierLast4.slice(-4)
          //console.log("get_latest_data - SSN last 4 digits", tempConsumerIdentifierLast4)
          tempCollection = collection
          //console.log("get_latest_data - Temp collection", tempCollection)
        }
      } catch (e) {
        isCompleted = false
        //console.log("get_latest_data - Catch error", e)
      }
      // Related to retrieve latest account data END

      setCollectionResponse({ ...collection });
      setPartnerReferenceNumber(
        _.get(collection, "metadata.partner_reference_number", "")
      );
      setStatus("completed");
    }
    setTransactionsResult({ ...trResult });
  }

  function getStatusColor() {
    if (collectionResponse == null) {
      return "#5E84FF";
    }
    const state = _.get(collectionResponse, "metadata.invocation_status", null);
    //console.log("getStatusColor", state);
    if (state === "PENDING") {
      return "#5E84FF";
    } else if (state === "COMPLETED") {
      return "#1E9C0A";
    } else if (state === "FAILED") {
      return "#D5320F";
    } else {
      return "#5E84FF";
    }
  }

  function formatCashOrMarketValue(value) {
    let formatting_options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    };
    return new Intl.NumberFormat("en-US", formatting_options).format(value);
  }

  function getFinancialInstitutionName(id) {
    const result = _.find(
      _.get(collectionResponse, "data.organizations", []),
      function (organization) {
        return organization["@id"] === id;
      }
    );

    if (result) {
      return result.has_organization_name.has_value;
    }
  }

  function formatAccountType(value) {
    switch (value) {
      case "checking_account":
        return "Checking Account";
      case "savings_account":
        return "Savings Account";
      default:
        return "Checking Account";
    }
  }

  useInterval(async () => {
    try {
      if (
        transactionsResult.response_payload &&
        transactionsResult.response_payload.status === "processing"
      ) {
        const trRes = await getTransactionInfo(
          transactionsResult.response_payload.id,
          domain,
          apiKey,
          "asset"
        );
        if (trRes.response_payload.status !== "processing") {
          const referenceNumber = _.get(
            trRes,
            "response_payload.response.referenceNumber",
            null
          );
          if (referenceNumber) {
            await getReferenceResult(trRes, referenceNumber);
          }
        }
      }
    } catch (e) {
      //console.log("Error in polling transaction", e);
    }
  }, 10000);

  function getFirstName() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.firstName`,
      ""
    );
  }

  function getLastName() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.lastName`,
      ""
    );
  }

  function getSSNName() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.taxIdentificationIdentifier`,
      ""
    );
  }

  function getCompany() {
    return _.get(
      transactionsResult,
      `response_payload.request.loan.applications[${loanIndex}].${borrowerType}.employment`,
      []
    )
      .filter((emp) => emp.currentEmploymentIndicator === true)
      .reduce((prev, curr) => prev + ", " + curr.employerName, "")
      .substring(1);
  }

  const transactionHistoryClicked = async function (evt) {
    evt.preventDefault();
    history.push({
      pathname: "/transactions",
      state: {
        transactionRequests: [
          {
            transactionId: transactionRequests[0].transactionId,
          },
        ],
        domain,
        apiKey,
        verificationType,
      },
    });
  };

  function addAssets(assets) {
    return <>{assets.map((item) => addAssetHelper(item))}</>;
  }

  async function refreshEquifaxAsset() {
    try {
      let initResult = await initializeOriginationContext(host);
      const promiseArray = [];
      let additionalInformation = [];

      additionalInformation.push({
        key: "referenceNumber",
        value: partnerReferenceNumber,
      });
      let ssn = getSSNName();
      additionalInformation.push({
        key: "consumerIdentifierLast4",
        value: ssn.slice(ssn.length - 4),
      });

      let requestObject = {
        request: {
          type: "Asset Verification",
          options: {
            borrowerType: borrowerType,
            partner_name: partnerName,
            additionalInformation: additionalInformation,
          },
        },
      };

      promiseArray.push(
        createTransaction(
          requestObject,
          initResult.transactionObject,
          initResult.encompassOriginResult
        )
      );
      await new Promise((r) => setTimeout(r, 200));
      await Promise.all(promiseArray);

      history.push({
        pathname: "/transactions",
        state: {
          transactionRequests,
          domain,
          apiKey,
          verificationType,
          isRefreshTransaction: true,
        },
      });
    }
    catch {
      console.log("Error opening transaction history page.")
      history.push({
        pathname: "/error",
        state: {
          error: "Error opening transaction history page.",
          domain: domain,
          apiKey: apiKey
        },
      });
    }
  }

  function addAssetHelper(item) {
    return (
      <div class="verificationResultContent" style={{ marginTop: "12px" }}>
        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Account Identifier:</span>
            <span class="verificationValue">
              {_.get(item, "has_asset_account_identifier", {}).has_value}
            </span>
          </div>
        </div>
        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">
              Account In Name of Description:
            </span>
            <span class="verificationValue">
              {_.get(item, "has_asset_description", {}).has_value}
            </span>
          </div>
        </div>
        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Account Type:</span>
            <span class="verificationValue">
              {formatAccountType(_.get(item, "has_asset_type", {}).has_value)}
            </span>
          </div>
        </div>

        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Financial Institution Name:</span>
            <span class="verificationValue">
              {getFinancialInstitutionName(
                _.get(item, "held_by_financial_institution", [])[0]
              )}
            </span>
          </div>
        </div>
        <div class="verification-row-1-1-1">
          <div class="verification-block-1-1">
            <span class="verificationLabel">Cash/Market Value:</span>
            <span class="verificationValue">
              {formatCashOrMarketValue(
                _.get(item, "has_market_value_amount", {}).has_value.toString()
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Toaster position="top-center" />
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div
          id="verification-result-container"
          className="pageContainer"
          style={{ marginBottom: "200px" }}
        >
          <div className="verificationHeaderHolder">
            <p
              id="verificationHeader"
              className="verificationResultMainContentHeader"
            >
              Assets Verification Result
            </p>
          </div>
          <div className="breadcrumbHolder">
            <Breadcrumb>
              <Breadcrumb.Item onClick={transactionHistoryClicked} href="/">
                Transaction History
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Verification Result</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="sectionContainer">
            <div className="verificationHeaderContainer">
              <p className="verificationResultContentHeader">Borrower Data</p>
            </div>

            <div className="verificationResultContent">
              <div className="verification-row">
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">First Name:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="firstName">
                      {getFirstName()}
                    </span>
                  </div>
                </div>
                <div className="verification-block-2-5">
                  <div>
                    <span className="verificationLabel">Last Name:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="lastName">
                      {getLastName()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionContainer">
            <div className="verificationHeaderContainer">
              <p className="verificationResultContentHeader">Verification</p>
            </div>

            <div className="verificationResultContent">
              <div className="verification-row">
                <div className="verification-block-1-2">
                  <div>
                    <span className="verificationLabel">Type:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span className="verificationValue" id="verificationType">
                      {toUpperFirstChar(verificationType)}
                    </span>
                  </div>
                </div>

                {partnerName === "equifax" ? (
                  <div className="verification-block-1-4">
                    <div>
                      <span className="verificationLabel">
                        Equifax Reference Number:
                      </span>
                    </div>
                    <div className="verificationValueDiv">
                      <span className="verificationValue" id="verificationType">
                        {partnerReferenceNumber}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="verification-row">
                <div className="verification-block-1-2">
                  <div>
                    <span className="verificationLabel">Request Date:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span
                      className="verificationValue"
                      id="verificationRequestDate"
                    >
                      {collectionResponse
                        ? getAmericanDateFromDate(
                          _.get(
                            collectionResponse,
                            "metadata.created_at",
                            new Date().toLocaleString()
                          )
                        )
                        : new Date().toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="verification-block-1-4">
                  <div>
                    <span className="verificationLabel">Status:</span>
                  </div>
                  <div className="verificationValueDiv">
                    <span
                      className="verificationValue"
                      id="verificationStatus"
                      style={{
                        color: getStatusColor(),
                      }}
                    >
                      {toUpperFirstChar(
                        collectionResponse
                          ? _.get(
                            collectionResponse,
                            "metadata.invocation_status",
                            "Processing"
                          )
                          : "Processing"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {_.get(collectionResponse, "data.assets", "") ? (
            <div className="sectionContainer" id="asset-container">
              <div className="verificationHeaderContainer">
                <p className="verificationResultContentHeader">
                  Asset Information
                </p>

                {latestAccountInfoMessage != "" ? (
                  <p style={{ color: latestAccountInfoMessageColor }}>{latestAccountInfoMessage}</p>
                ) : null}

                {partnerName === "equifax" &&
                  _.get(
                    collectionResponse,
                    "metadata.invocation_status",
                    "Processing"
                  ) === "COMPLETED" ? (
                  <div>
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      overlay={popover}
                    >
                      <button
                        disabled={refreshButtonClicked}
                        onClick={async () => {
                          setRefreshButtonClicked(true);
                          await refreshEquifaxAsset();
                          setRefreshButtonClicked(false);
                        }}
                        className="refreshButton"
                      >
                        Refresh
                        {refreshButtonClicked === true ? (
                          <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            id="loader"
                            style={{
                              position: "relative",
                              left: "5%",
                            }}
                          />
                        ) : null}
                      </button>
                    </OverlayTrigger>

                    <button
                      disabled={importButtonClicked}
                      onClick={async () => {
                        setImportButtonClicked(true);

                        if (latestTransactionId != "") {
                          await populateData(
                            transactionRequests[0].transactionId,
                            latestTransactionId,
                            latestResponseCollectionId,
                            domain,
                            apiKey
                          );
                        } else {
                          await populateData(
                            transactionRequests[0].transactionId,
                            _.get(collectionResponse, "transaction_id", ""),
                            _.get(collectionResponse, "collection_id", ""),
                            domain,
                            apiKey
                          );
                        }

                        /* await populateData(
                          transactionRequests[0].transactionId,
                          _.get(collectionResponse, "transaction_id", ""),
                          _.get(collectionResponse, "collection_id", ""),
                          domain,
                          apiKey
                        ); */
                        toast("Successfully imported accounts.", {
                          duration: 4000,
                          style: {},
                          className: "",
                          // Custom Icon
                          icon: "✅",

                          // Change colors of success/error/loading icon
                          iconTheme: {
                            primary: "#000",
                            secondary: "#fff",
                          },

                          // Aria
                          ariaProps: {
                            role: "status",
                            "aria-live": "polite",
                          },
                        });
                        setImportButtonClicked(false);
                      }}
                      className="importButton"
                    >
                      Import Accounts
                      {importButtonClicked === true ? (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={20}
                          width={20}
                          id="loader"
                          style={{
                            position: "relative",
                            left: "5%",
                          }}
                        />
                      ) : null}
                    </button>
                  </div>
                ) : null}
                <button
                  disabled={viewDocumentButton}
                  onClick={async () => {
                    setViewDocumentButton(true);

                    try {

                      let resource_id = ""

                      try {
                        resource_id = _.get(transactionsResult, "response_payload.response.resources[0].id", "")
                      } catch {
                        //console.log("document-preview get resource_id catch", resource_id)
                        resource_id = ""
                      }

                      //console.log("document-preview resource_id", resource_id)

                      if (resource_id != "") {
                        //console.log("document-preview resource_id", resource_id)
                        let resource = {
                          target: {
                            entityId: resource_id,
                            entityType: "urn:elli:skydrive"
                          }
                        }
                        let execution_status = await openModal(resource)
                        //console.log("document-preview execution_status", resource_id)
                        if (!execution_status) {
                          setLatestAccountInfoMessageColor("#D5320F")
                          setLatestAccountInfoMessage("Unable to retrieve and view the document related to this transaction.")
                        }
                        /* await downloadReport(
                          _.get(transactionsResult, "response_payload.id", ""),
                          domain,
                          apiKey,
                          "asset"
                        ); */

                      } else {
                        //console.log("document-preview no resource id", resource_id)
                        setLatestAccountInfoMessageColor("#D5320F")
                        setLatestAccountInfoMessage("There are no documents related to this transaction.")
                      }

                    } catch {
                      setLatestAccountInfoMessageColor("#D5320F")
                      setLatestAccountInfoMessage("Unable to retrieve and view the document related to this transaction.")
                    }

                    setViewDocumentButton(false);
                  }}
                  className="downloadButton"
                >
                  View
                  {viewDocumentButton === true ? (
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      id="loader"
                      style={{
                        position: "relative",
                        left: "5%",
                      }}
                    />
                  ) : null}
                </button>
              </div>

              <div id="assets">
                {_.get(collectionResponse, "data.assets", "")
                  ? addAssets(_.get(collectionResponse, "data.assets", ""))
                  : "Waiting for verification result"}
              </div>
            </div>
          ) : partnerName === "equifax" ? (
            <div className="sectionContainer" id="asset-container">
              <div className="statusCheckContainer">
                <button
                  disabled={verifButtonClicked}
                  onClick={async () => {
                    setVerifButtonClicked(true);
                    //console.log("on_demand_status_check ssn", getSSNName())
                    //console.log("on_demand_status_check collection_response", collectionResponse)
                    let result = await onDemandStatusCheck(
                      domain,
                      apiKey,
                      getSSNName(),
                      collectionResponse
                    );

                    //console.log("on_demand_status_check result", result)

                    if (result === "Retrieve Successful") {
                      let collection = await getStaircaseCollection(
                        domain,
                        apiKey,
                        collectionResponse.transaction_id,
                        collectionResponse.collection_id,
                        "asset"
                      );

                      /* while (collection.metadata.invocation_status !== 'COMPLETED' && collection.metadata.invocation_status !== 'FAILED') {

                        collection = await getStaircaseCollection(
                          domain,
                          apiKey,
                          collectionResponse.transaction_id,
                          collectionResponse.collection_id,
                          "asset"
                        );
                      } */

                      let i = 0;

                      do {

                        //console.log("on_demand_status_check do_while", i)

                        if (collection.metadata.invocation_status == 'COMPLETED' && collection.metadata.invocation_status == 'FAILED') {
                          i = 10
                        } else {
                          collection = await getStaircaseCollection(
                            domain,
                            apiKey,
                            collectionResponse.transaction_id,
                            collectionResponse.collection_id,
                            "asset"
                          );
                          await new Promise((r) => setTimeout(r, 1000));
                          i++;
                        }

                      }
                      while (i < 10);

                      if (collection.metadata.invocation_status != 'COMPLETED') {
                        result = "Failed to retrieve latest status."
                      }

                      await initPending();
                    }
                    setVerificationResult(result);
                    setVerifButtonClicked(false);
                  }}
                  className="checkVerificationButton"
                >
                  Check Status
                  {verifButtonClicked === true ? (
                    <Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      id="loader"
                      style={{
                        position: "relative",
                        left: "5%",
                      }}
                    />
                  ) : null}
                </button>
                {verificationResult ? (
                  <p style={{ marginTop: "15px" }}>{verificationResult}</p>
                ) : verifButtonClicked ? (
                  <p style={{ marginTop: "15px" }}>
                    {" "}
                    Waiting for status check ...
                  </p>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default AssetVerificationResultPage;
