import _ from "lodash";
import { getTransactionInfo, getPartners } from "../service/staircaseService";
import { create_health_metric } from "./healthService";
import { getUIDomain } from "../util/helper";

async function initializeOriginationContext(host) {
  try {
    let originationContext = {};
    let transactionObject = {};
    let partnerAccessToken = {};
    let originationId = {};
    let ui_domain = getUIDomain();
    if (window.location.host !== "localhost:3000") {
      transactionObject = await host.getObject("transaction");
      originationContext = await transactionObject.getOrigin();
      originationId = originationContext.id;
      partnerAccessToken = originationContext.partnerAccessToken;
    }

    const encompassOriginResult = await getEncompassOrigination(
      originationContext,
      partnerAccessToken,
      originationId,
      ui_domain
    );
    console.log("initializeOriginationContext", {
      encompassOriginResult,
      originationContext,
      transactionObject,
      ui_domain,
    });

    const domain = _.get(
      encompassOriginResult.encompassOriginData,
      "response_payload.credentials.domain",
      ""
    );
    const apiKey = _.get(
      encompassOriginResult.encompassOriginData,
      "response_payload.credentials.api_key",
      ""
    );
    let transaction_id = _.get(
      encompassOriginResult.encompassOriginResult,
      "encompassOriginData.transaction_id",
      ""
    );
    await create_health_metric(
      domain,
      apiKey,
      "STARTED",
      "",
      "",
      "logging",
      "Got Encompass origination",
      transaction_id,
      "/"
    );
    const is_credentials_valid = await validate_credentials(domain, apiKey);

    if (is_credentials_valid === false) {
      throw Error(
        "Staircase Credentials are not set correctly by your admin. They can be set on Services Management menu of admin panel."
      );
    }

    return {
      encompassOriginResult,
      originationContext,
      transactionObject,
    };
  } catch (error) {
    console.log("initializeOriginationContext error ", error);
    throw error;
  }
}

async function validate_credentials(domain, api_key) {
  const res = await fetch(
    "https://" + domain + "/product/products/asset/partners",
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
      },
    }
  );

  return res.ok;
}

async function getEncompassOrigination(
  originationContext,
  partnerAccessToken,
  originationId,
  ui_domain
) {

  try {
    let data = {
      origin_id: originationId,
      partner_access_token: partnerAccessToken,
    };

    const res = await fetch(
      "https://" + ui_domain + "/los/services/encompass-get-origination/",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (window.location.host === "localhost:3000" || res.ok) {
      let encompassOriginData = null;
      encompassOriginData = await res.json();
      const borrowerEmployments = _.get(
        encompassOriginData,
        "response_payload.loan.applications[0].borrower.employment",
        []
      ).map((emp) => ({
        ...emp,
        status: false,
      }));
      const coborrowerEmployments = _.get(
        encompassOriginData,
        "response_payload.loan.applications[0].coborrower.employment",
        []
      ).map((emp) => ({
        ...emp,
        status: false,
      }));

      console.log(encompassOriginData);
      const domain = _.get(
        encompassOriginData,
        "response_payload.credentials.domain",
        ""
      );
      const apiKey = _.get(
        encompassOriginData,
        "response_payload.credentials.api_key",
        ""
      );

      if (domain === "" || apiKey === "") {
        console.error(
          "Staircase Credentials are not set by your admin. They can be set on Services Management menu of admin panel."
        );
        throw Error(
          "Staircase Credentials are not set by your admin. They can be set on Services Management menu of admin panel."
        );
      }

      return {
        encompassOriginData,
        borrowerEmployments,
        coborrowerEmployments,
      };
    } else {
      console.error("Error on getEncompassOrigination result", res);
      let response = await res.json()
      console.error("Error on getEncompassOrigination result response", response);
      let encompass_origin_data = null
      let borrower_employments = null
      let coborrower_employments = null
      return {
        encompass_origin_data,
        borrower_employments,
        coborrower_employments,
      };
    }
  } catch (e) {
    console.log("Error on getEncompassOrigination", e);
    let encompass_origin_data = null
    let borrower_employments = null
    let coborrower_employments = null
    return {
      encompass_origin_data,
      borrower_employments,
      coborrower_employments,
    };
  }
}

async function createTransaction(
  transactionRequest,
  transactionObject,
  encompassOriginResult
) {
  try {
    const transactionData = await transactionObject.create(transactionRequest);
    console.log("transaction data");
    console.log(transactionData);
    const transactionId = transactionData.id;
    transactionRequest.transactionId = transactionId;

    let domain =
      encompassOriginResult["encompassOriginData"]["response_payload"][
      "credentials"
      ]["domain"];
    let apiKey =
      encompassOriginResult["encompassOriginData"]["response_payload"][
      "credentials"
      ]["api_key"];

    let productName =
      encompassOriginResult["encompassOriginData"]["response_payload"][
        "product"
      ]["name"].toLowerCase();

    if (
      productName.startsWith("staircaseasset") ||
      productName.startsWith("staircasevoa") ||
      productName.includes("asset") ||
      productName.includes("Asset")
    ) {
      let data = {
        product_flow_name: "process-encompass-epc-transaction",
        request_data: {
          organizations: [
            {
              has_transaction_identifier: {
                has_value: transactionId,
              },
            },
          ],
        }
      };
      const res = await fetch(
        "https://" + domain + "/product/products/asset/invocations",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
          body: JSON.stringify(data),
        }
      );

      if (res.ok) {
        const productCallInfo = await res.json();
        console.log("RES OK");
        console.log(productCallInfo);
      } else {
        console.error("Error on calling  result", res);
      }
    }

    return transactionRequest;
  } catch (error) {
    console.log(error);
  }
}

export { initializeOriginationContext, createTransaction };
