import _ from "lodash";
import { createNewHealthMetric } from "../service/healthService";

async function retrieveProductFlowInvocationStatus(domain, api_key, invocation_id) {

  try {

    const response = await fetch(
      "https://" + domain + "/product/products/asset/invocations/" + invocation_id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
        }
      }
    );

    if (response.ok) {
      let json_response = await response.json()
      return json_response
    }

    return "Failed to retrieve invocation status."

  } catch (error) {
    return "Failed to retrieve invocation status"
  }
}

async function retrieveLatestAccountData(domain, api_key, reference_number, consumer_identifier_las_4) {

  try {

    let request_payload = {
      "product_flow_name": "retrieve-latest-account-data",
      "request_data": {
        "additional_information": [
          {
            "@type": "additional_information",
            "@id": "01FD6ZNGJADZ0RB1H96FSEAA01",
            "has_additional_information_key_identifier": {
              "has_value": "referenceNumber"
            },
            "has_additional_information_value": {
              "has_value": reference_number
            }
          },
          {
            "@type": "additional_information",
            "@id": "01FD6ZNGJADZ0RB1H96FSEAA02",
            "has_additional_information_key_identifier": {
              "has_value": "consumerIdentifierLast4"
            },
            "has_additional_information_value": {
              "has_value": consumer_identifier_las_4
            }
          }
        ]
      }
    }

    const response = await fetch(
      "https://" + domain + "/product/products/asset/invocations",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
        },
        body: JSON.stringify(request_payload),
      }
    );

    if (response.ok) {
      let json_response = await response.json()
      return json_response["invocation_id"]
    }

    return ""

  } catch (error) {
    return ""
  }

}

async function getTransactionInfo(transactionId, domain, apiKey, productName) {
  let data = {
    request_payload: {
      resource_id: transactionId,
    },
  };

  let queryParams = "";

  if (productName) {
    queryParams = "?product_name=" + productName;
  }

  try {
    const res = await fetch(
      "https://" +
      domain +
      "/connector-jobs/vendors/encompass-epc/lookups/get_encompass_transaction/jobs" +
      queryParams,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();

      console.log("getTransactionInfo", data);
      return data;
    } else if (res.status === 403) {
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "getTransactionInfo",
        statusCode: "4001",
        status: "FAILED",
      });
      throw Error("Unauthorized getting transaction info");
    } else {
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "getTransactionInfo",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: productName,
      apiName: "getTransactionInfo",
      statusCode: "6005",
      status: "FAILED",
    });
    throw Error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getLoanTransactions(
  domain,
  apiKey,
  entity_id,
  limit,
  start,
  productName
) {
  let data = {
    request_payload: {
      entity_id: entity_id,
      limit: limit,
      start: start,
    },
  };

  let queryParams = "";

  if (productName) {
    queryParams = "?product_name=" + productName;
  }

  try {
    const res = await fetch(
      "https://" +
      domain +
      "/connector-jobs/vendors/encompass-epc/lookups/get_encompass_loan_transactions/jobs" +
      queryParams,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();

      console.log("getTransactionInfo", data);
      return data;
    } else if (res.status === 403) {
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "getLoanTransactions",
        statusCode: "4001",
        status: "FAILED",
      });
    } else {
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "getLoanTransactions",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: productName,
      apiName: "getLoanTransactions",
      statusCode: "6005",
      status: "FAILED",
    });
    throw Error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getPartners(domain, apiKey, product) {
  try {
    const res = await fetch("https://" + domain + "/" + product + "/partners", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
    });

    if (res.ok) {
      return res.json();
    } else if (res.status === 403) {
      console.error(
        "Your Staircase credentials are not valid. Please contact your admin."
      );
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "getPartners",
        statusCode: "4001",
        status: "FAILED",
      });
    } else {
      console.error("Error occured.", res);
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "getPartners",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: "asset",
      apiName: "getPartners",
      statusCode: "6005",
      status: "FAILED",
    });
    console.error(
      "Your Staircase credentials are not valid. Please contact your admin."
    );
  }
}

async function getStaircaseCollection(
  domain,
  apiKey,
  transactionId,
  collectionId,
  productName
) {
  try {
    const res = await fetch(
      "https://" +
      domain +
      "/persistence/transactions/" +
      transactionId +
      "/collections/" +
      collectionId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.log("An error occurred while getting staircase collection", res);
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "getStaircaseCollection",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: productName,
      apiName: "getStaircaseCollection",
      statusCode: "6005",
      status: "FAILED",
    });
    console.log("An error occurred while getting staircase collection", e);
  }
}

async function retrieveConnectorFlowExecutionStatus(
  domain,
  apiKey,
  vendorName,
  flowName,
  jobId
) {
  try {
    const res = await fetch(
      `https://${domain}/connector-jobs/vendors/${vendorName}/flows/${flowName}/jobs/${jobId}?product_name=asset`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "retrieveConnectorFlowExecutionStatus",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: "asset",
      apiName: "retrieveConnectorFlowExecutionStatus",
      statusCode: "6005",
      status: "FAILED",
    });
    console.log("An error occurred while getting staircase collection", e);
  }
}

async function downloadReport(trId, domain, apiKey, productName) {
  try {
    let data = {
      request_payload: {
        resource_id: trId,
      },
    };
    let queryParams = "";

    if (productName) {
      queryParams = "?product_name=" + productName;
    }

    const res = await fetch(
      "https://" +
      domain +
      "/connector-jobs/vendors/encompass-epc/lookups/get_encompass_transaction_resources/jobs" +
      queryParams,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();
      const entity = _.get(data, "response_payload[0]", null);
      if (entity) {
        const location = entity.location;
        const authorization = entity.authorization;

        const response = await fetch(location, {
          method: "GET",
          mode: "cors",
          headers: new Headers({
            Authorization: authorization,
          }),
        });
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "report.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } else {
      await createNewHealthMetric(domain, apiKey, {
        productName: productName,
        apiName: "downloadReport",
        statusCode: "6005",
        status: "FAILED",
      });
      console.log(res);
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: productName,
      apiName: "downloadReport",
      statusCode: "6005",
      status: "FAILED",
    });
    console.error("Error while downloading report", e);
  }
}

async function onDemandStatusCheck(
  domain,
  apiKey,
  ssn,
  collection
) {

  try {
    
    const transactionId = _.get(collection, "transaction_id", "");
    const collectionId = _.get(collection, "collection_id", "");

    console.log("on_demand_status_check transaction_id", transactionId)
    console.log("on_demand_status_check collection_id", collectionId)

    if (transactionId == "") {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "onDemandStatusCheck",
        statusCode: "6005",
        status: "FAILED",
        elapsedTime: 1.1,
        transactionId: "missing_transaction_id"
      });
      return "Failed to retrieve latest status."
    }

    if (collectionId == "") {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "onDemandStatusCheck",
        statusCode: "6005",
        status: "FAILED",
        elapsedTime: 1.2,
        collectionId: "missing_collection_id"
      });
      return "Failed to retrieve latest status."
    }

    let i = 0;

    do {

      console.log("on_demand_status_check do_while", i)

      if (_.get(collection, "metadata.partner_reference_number", "")) {
        i = 2
      } else {
        collection = await getStaircaseCollection(
          domain,
          apiKey,
          transactionId,
          collectionId,
          "asset"
        );
        await new Promise((r) => setTimeout(r, 2000));
        i++;
      }

    }
    while (i < 2);

    console.log("on_demand_status_check reference_number", _.get(collection, "metadata.partner_reference_number", ""))

    if (!_.get(collection, "metadata.partner_reference_number", "")) {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "onDemandStatusCheck",
        statusCode: "6005",
        status: "FAILED",
        elapsedTime: 1.3,
        transactionId: transactionId,
        collectionId: collectionId
      });
      return "Failed to retrieve latest status."
    }

    if (!_.get(collection, "metadata.asset_flow_job_id", "")) {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "onDemandStatusCheck",
        statusCode: "6005",
        status: "FAILED",
        elapsedTime: 1.33,
        transactionId: transactionId,
        collectionId: collectionId
      });
      return "Failed to retrieve latest status."
    }

    /*     while (!_.get(collection, "metadata.partner_reference_number", "")) {
          collection = await getStaircaseCollection(
            domain,
            apiKey,
            transactionId,
            collectionId,
            "asset"
          );
          await new Promise((r) => setTimeout(r, 1000));
        } */
    
    

    let data = {
      transaction_id: transactionId,
      response_collection_id: collectionId,
      callback_url: 'https://bushnell-production.staircaseapi.com/job/webhooks/encompass-asset',
      request_payload: {
        "asset-initiate-data": {
          consumer: {
            identifier: ssn,
          },
        },
        reference_number: _.get(
          collection,
          "metadata.partner_reference_number",
          ""
        ),
        main_flow_job_id: _.get(collection, "metadata.asset_flow_job_id", ""),
      },
    };

    let jobId = "";
    let vendorName = "equifax";
    let flowName = "asset-on-demand-status-checker";
    const res = await fetch(
      `https://${domain}/connector-jobs/vendors/${vendorName}/flows/${flowName}/jobs?product_name=asset`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      console.log("on_demand_status_check flow_init", "ok")
      const data = await res.json();
      jobId = data.job_id;
      await new Promise((r) => setTimeout(r, 1000));
      let response = await retrieveConnectorFlowExecutionStatus(
        domain,
        apiKey,
        vendorName,
        flowName,
        jobId
      );

      let i = 0;

      do {

        console.log("on_demand_status_check retrieveConnectorFlowExecutionStatus", i)

        if (_.get(response, "status", "") !== "RUNNING") {
          i = 10
        } else {
          response = await retrieveConnectorFlowExecutionStatus(
            domain,
            apiKey,
            vendorName,
            flowName,
            jobId
          );
          await new Promise((r) => setTimeout(r, 2000));
          i++;
        }

      }
      while (i < 10);

/*       while (_.get(response, "status", "") === "RUNNING") {
        response = await retrieveConnectorFlowExecutionStatus(
          domain,
          apiKey,
          vendorName,
          flowName,
          jobId
        );
        await new Promise((r) => setTimeout(r, 1000));
      } */

      if (_.get(response, "status", "") === "RUNNING") {
        console.log("on_demand_status_check stuck_in_running_status", "RUNNING")
        await createNewHealthMetric(domain, apiKey, {
          productName: "asset",
          apiName: "onDemandStatusCheck",
          statusCode: "6005",
          status: "FAILED",
          elapsedTime: 1.59,
          transactionId: transactionId,
          collectionId: collectionId
        });
        return "Failed to retrieve latest status."
      }

      if (_.get(response, "status", "") === "FAILED") {
        console.log("on_demand_status_check flow_status", "FAILED")
        let unexpectedError = false
        let executedEvents = []
        try {
          executedEvents = _.get(response, "executed_events", "")
        } catch {
          executedEvents = []
        }
        console.log("executedEvents", executedEvents)

        if (!executedEvents.includes("FailState")) {
          unexpectedError = true
          console.log("on_demand_status_check flow_unexpected_error", "TRUE")
        }

        if (unexpectedError) {
          await createNewHealthMetric(domain, apiKey, {
            productName: "asset",
            apiName: "onDemandStatusCheck",
            statusCode: "6005",
            status: "FAILED",
            elapsedTime: 1.5,
            transactionId: transactionId,
            collectionId: collectionId
          });
          console.log("Failed to retrieve latest status.")
          return "Failed to retrieve latest status."
        }
      }
    } else {
      console.log("on_demand_status_check flow_init", "FAILED")
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "onDemandStatusCheck",
        statusCode: "6005",
        status: "FAILED",
        elapsedTime: 1.4,
        transactionId: transactionId,
        collectionId: collectionId
      });
      console.log(res);
      return "Failed to retrieve latest status."
    }
    collection = await getStaircaseCollection(
      domain,
      apiKey,
      transactionId,
      collectionId,
      "asset"
    );
    return collection.metadata.partner_status;
  } catch (e) {
    console.log("on_demand_status_check catch")
    await createNewHealthMetric(domain, apiKey, {
      productName: "asset",
      apiName: "onDemandStatusCheck",
      statusCode: "6005",
      status: "FAILED",
      elapsedTime: 2.0
    });
    console.error("Error while fetching on demand verification", e);
    return "Failed to retrieve latest status."
  }
}

async function populateData(
  encompassTransactionId,
  transactionId,
  collectionId,
  domain,
  apiKey
) {
  try {

    let data = {
      transaction_id: transactionId,
      response_collection_id: collectionId,
      callback_url: 'https://bushnell-production.staircaseapi.com/job/webhooks/encompass-asset',
      request_payload: {
        transaction_id: transactionId,
        response_collection_id: collectionId,
        epc_transaction_id: encompassTransactionId,
      },
    };

    let jobId = "";
    let vendorName = "encompass-epc";
    let flowName = "populate-data";
    const res = await fetch(
      `https://${domain}/connector-jobs/vendors/${vendorName}/flows/${flowName}/jobs?product_name=asset`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      const data = await res.json();
      jobId = data.job_id;
      await new Promise((r) => setTimeout(r, 1000));
      let response = await retrieveConnectorFlowExecutionStatus(
        domain,
        apiKey,
        vendorName,
        flowName,
        jobId
      );
      while (_.get(response, "status", "") === "RUNNING") {
        response = await retrieveConnectorFlowExecutionStatus(
          domain,
          apiKey,
          vendorName,
          flowName,
          jobId
        );
        await new Promise((r) => setTimeout(r, 1000));
      }
      return response;
    } else {
      await createNewHealthMetric(domain, apiKey, {
        productName: "asset",
        apiName: "populateData",
        statusCode: "6005",
        status: "FAILED",
      });
    }
  } catch (e) {
    await createNewHealthMetric(domain, apiKey, {
      productName: "asset",
      apiName: "populateData",
      statusCode: "6005",
      status: "FAILED",
    });
    console.error("Error while populating data", e);
  }
}

export {
  getLoanTransactions,
  getTransactionInfo,
  getPartners,
  downloadReport,
  getStaircaseCollection,
  onDemandStatusCheck,
  populateData,
  retrieveLatestAccountData,
  retrieveProductFlowInvocationStatus
};
