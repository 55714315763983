import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "react-loader-spinner";
import {
  getLoanTransactions,
  getTransactionInfo,
  getStaircaseCollection,
} from "../service/staircaseService";
import { toUpperFirstChar } from "../util/helper";
import { initializeOriginationContext } from "../service/encompassService";
import Table from "react-bootstrap/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

function TransactionListPage({ data }) {
  console.log("TransactionListPage");
  const transactionHistoryHeaders = [
    "Date",
    "Operation Name",
    "Applicant Name",
    "Applicant Type",
    "Partner Name",
    "Partner Reference Number",
    "Partner Response",
    "Status",
    "Fail Reason",
  ];
  const { host } = data;
  const history = useHistory();
  const location = useLocation();
  // var domain = "ibrahim-test.staircaseapi.com";
  // var apiKey = "776fa92b-a8bb-4618-b51c-94be88283f36";
  var { domain, apiKey } = location.state;
  const [entityId, setEntityId] = useState("");
  const [transactionsResult, setTransactionsResult] = useState([]);
  const [detailsResult, setDetailsResult] = useState([]);
  const [collectionResult, setCollectionResult] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const initPending = async () => {
      const initResult = await initializeOriginationContext(host);
      //console.log("JF - Transaction history page start. Init result:", initResult)
      const entity_id = _.get(
        initResult.encompassOriginResult,
        "encompassOriginData.response_payload.entityRef.entityId",
        ""
      );

      //const entity_id = "urn:elli:encompass:TEBE11229152:loan:48b77970-ae3b-4173-b55c-e831d06c6527"

      //console.log("JF - Entity ID:", entity_id)

      setEntityId(entity_id);

      let metadataReady = false

      do {
        metadataReady = await checkIfMetadataReady(entity_id)
        //console.log("JF - Metadata ready:", metadataReady)
        await new Promise(r => setTimeout(r, 2000));
      }
      while (!metadataReady)

      await loadTransactions(entity_id, 0);

      //console.log("JF - Transaction history page end.")

      setLoading(false);
    };
    initPending();
    initPending().catch((error)=> {
      history.push({
          pathname: "/error",
          state: {
            error:"Transaction history page load failed.",
            domain: domain,
            apiKey: apiKey
          },
        });
    });
  }, []);

  async function checkIfMetadataReady(entity_id) {
    const res = await getLoanTransactions(
      domain,
      apiKey,
      entity_id,
      2,
      0,
      "asset"
    );

    const transactions = _.get(res, "response_payload", null);

    //console.log("JF - Transactions:", transactions)

    const details = await Promise.all(
      transactions.map((tr) => {
        return getDetails(tr);
      })
    );

    //console.log("JF - Details:", details)

    const collections = await Promise.all(
      details.map((detail) => {
        return getCollections(detail);
      })
    );

    //console.log("JF - Collections:", collections)

    //console.log("JF - Collections size:", collections.length)

    let collectionsSize = collections.length

    if (collectionsSize == 0) {
      return true
    }

    let reference_number_1 = false
    let reference_number_2 = false

    try {
      if ("partner_reference_number" in collections[0]["metadata"] || "asset_verification_status" in collections[0]["metadata"] || "partner_status" in collections[0]["metadata"] || collections[0]["metadata"]["invocation_status"] == "FAILED") {
        //console.log("JF - 1st transaction reference number set. Reference number:", collections[0])
        reference_number_1 = true
      } else {
        //console.log("JF - 1st transaction reference number NOT set.")
        reference_number_1 = false
      }
    } catch {
      //console.log("JF - 1st transaction reference number not set.")
      reference_number_1 = false
    }

    if (collectionsSize == 2) {
      try {
        if ("partner_reference_number" in collections[1]["metadata"] || "asset_verification_status" in collections[1]["metadata"] || "partner_status" in collections[1]["metadata"] || collections[1]["metadata"]["invocation_status"] == "FAILED") {
          //console.log("JF - 2st transaction reference number set. Reference number:", collections[1])
          reference_number_2 = true
        } else {
          //console.log("JF - 2st transaction reference number NOT set.")
          reference_number_2 = false
        }
      } catch {
        //console.log("JF - 2st transaction reference number not set.")
        reference_number_2 = false
      }
    } else {
      reference_number_2 = true
    }

    //console.log("JF - REF 1:", reference_number_1)
    //console.log("JF - REF 2:", reference_number_2)

    if (reference_number_1 && reference_number_2) {
      return true
    } else {
      return false
    }
  }

  async function loadTransactions(entity_id, page) {
    setLoadingMore(true);
    await setCurrentPage(page);
    const res = await getLoanTransactions(
      domain,
      apiKey,
      entity_id,
      10,
      page * 10,
      "asset"
    );

    const transactions = _.get(res, "response_payload", null);

    //console.log("JF - Transactions:", transactions)

    const details = await Promise.all(
      transactions.map((tr) => {
        return getDetails(tr);
      })
    );

    //console.log("JF - Details:", details)

    const collections = await Promise.all(
      details.map((detail) => {
        return getCollections(detail);
      })
    );

    //console.log("JF - Collections:", collections)

/*     transactions = [
      {
        "id": "76d1a9a0-b7de-4a32-84f4-44f5e8dfeec7",
        "status": "processing",
        "clientId": "3011229152",
        "entityRef": {
          "entityId": "urn:elli:encompass:TEBE11229152:loan:02c8fadd-2667-470f-8c41-8d3eb6b54345",
          "entityType": "urn:elli:encompass:loan"
        },
        "product": {
          "name": "StaircaseVOATestVersion"
        },
        "application": "urn:elli:application:loconnect",
        "orderType": "MANUAL",
        "created": "2022-12-27T16:54:07Z",
        "createdBy": "urn:elli:service:soo",
        "updated": "2022-12-27T16:54:37Z",
        "updatedBy": "urn:elli:service:soo"
      }
    ]

    details = [
      {
        "transaction_id": "",
        "response_payload": {
          "id": "76d1a9a0-b7de-4a32-84f4-44f5e8dfeec7",
          "status": "processing",
          "clientId": "3011229152",
          "environment": "sandbox",
          "entityRef": {
            "entityId": "urn:elli:encompass:TEBE11229152:loan:02c8fadd-2667-470f-8c41-8d3eb6b54345",
            "entityType": "urn:elli:encompass:loan"
          },
          "product": {
            "id": "ec9dff05-81f3-4e78-aa3c-a2e4334d0dc8",
            "partnerId": "11228812",
            "name": "StaircaseVOATestVersion"
          },
          "request": {
            "loanFormat": "application/vnd.plm-2.0.0+json",
            "loan": {
              "id": "02c8fadd-2667-470f-8c41-8d3eb6b54345",
              "contacts": [
                {
                  "name": "Admin User",
                  "email": "soofi.safavi@staircase.co",
                  "contactType": "LOAN_OFFICER"
                }
              ],
              "property": {
                "loanPurposeType": "NoCash-Out Refinance"
              },
              "loanNumber": "TEST221206153",
              "applications": [
                {
                  "id": "bcc1d71f-aed5-4a4b-a8af-18974458c5dd",
                  "borrower": {
                    "id": "bcc1d71f-aed5-4a4b-a8af-18974458c5dd",
                    "lastName": "purchaser",
                    "birthDate": "1988-01-01",
                    "firstName": "patrick",
                    "residences": [
                      {
                        "addressCity": "Baltimore",
                        "addressState": "MD",
                        "residencyType": "Current",
                        "addressPostalCode": "20600"
                      }
                    ],
                    "emailAddressText": "josko.franic+pat@staircase.co",
                    "fullNameWithSuffix": "patrick purchaser",
                    "taxIdentificationIdentifier": "999-12-1235"
                  },
                  "coborrower": {
                    "id": "11da4916-e92b-4e4e-9e5f-8e557a952c66",
                    "lastName": "purchaser",
                    "birthDate": "1990-01-01",
                    "firstName": "lorraine",
                    "residences": [
                      {
                        "addressCity": "Baltimore",
                        "addressState": "MD",
                        "residencyType": "Current",
                        "addressPostalCode": "20600"
                      }
                    ],
                    "emailAddressText": "josko.franic+lor@staircase.co",
                    "fullNameWithSuffix": "lorraine purchaser",
                    "taxIdentificationIdentifier": "999-56-5678"
                  }
                }
              ],
              "interviewerName": "Admin User",
              "interviewerEmail": "soofi.safavi@staircase.co",
              "organizationCode": "TEST"
            },
            "credentials": {
              "domain": "russell-dev.staircaseapi.com",
              "api_key": "73884068-3362-4899-b7db-4c72e0ac46b2",
              "equifax_username": "StaircaseAssetViewIntgrtTEST",
              "equifax_password": "%JD1UlgnSv1%"
            },
            "requestingParty": {
              "name": "Demo Account - CSO #6",
              "address": "4155 Hopyard Road, Suite 200 null",
              "city": "Pleasanton",
              "state": "CA",
              "postalCode": "94588",
              "pointOfContact": {
                "name": "Admin User",
                "email": "soofi.safavi@staircase.co",
                "phone": "null"
              }
            },
            "options": {
              "loanIndex": "0",
              "borrowerType": "borrower",
              "partner_name": "equifax",
              "isAutoImportEnabled": false,
              "additionalInformation": [
                {
                  "key": "permissiblePurpose",
                  "value": "PPCREDIT"
                },
                {
                  "key": "monitoringDuration",
                  "value": "2M"
                }
              ]
            },
            "type": "Asset Verification"
          },
          "response": {
            "status": "processing",
            "partnerStatus": "Running",
            "referenceNumber": "01GNA82ZEK5ZJHBFEQEVG6B8BQ#01GNA83QXB9VVNDD9RYQH3KGGP"
          },
          "application": "urn:elli:application:loconnect",
          "orderType": "MANUAL",
          "created": "2022-12-27T16:54:07Z",
          "createdBy": "urn:elli:service:soo",
          "updated": "2022-12-27T16:54:37Z",
          "updatedBy": "urn:elli:service:soo",
          "events": [
            {
              "id": "f7016475-d121-464c-bc2c-68e91d7f9778",
              "text": "Transaction Request Created",
              "type": "urn:elli:epc:transaction:request:created",
              "from": "System",
              "sent": "2022-12-27T16:54:07Z"
            },
            {
              "id": "fe7b405d-5bae-44d4-882a-d2652d9fb2f1",
              "text": "Transaction Response Updated",
              "type": "urn:elli:epc:transaction:response:updated",
              "from": "System",
              "sent": "2022-12-27T16:54:21Z"
            },
            {
              "id": "70ae8f39-4de8-44dc-9409-789b78401848",
              "text": "Transaction response successfully processed",
              "type": "urn:elli:epc:transaction:response:processed",
              "from": "System",
              "sent": "2022-12-27T16:54:22Z",
              "comments": "Transaction response successfully processed",
              "details": {
                "status": "Processing",
                "requestType": "Asset Verification",
                "transactionId": "76d1a9a0-b7de-4a32-84f4-44f5e8dfeec7"
              }
            },
            {
              "id": "8f280b94-29b7-4342-ab45-1439276e2b7e",
              "text": "Transaction Response Updated",
              "type": "urn:elli:epc:transaction:response:updated",
              "from": "System",
              "sent": "2022-12-27T16:54:36Z"
            },
            {
              "id": "20d7d8fd-861c-4c34-b931-07762601acd7",
              "text": "Transaction response successfully processed",
              "type": "urn:elli:epc:transaction:response:processed",
              "from": "System",
              "sent": "2022-12-27T16:54:37Z",
              "comments": "Transaction response successfully processed",
              "details": {
                "status": "Processing",
                "requestType": "Asset Verification",
                "transactionId": "76d1a9a0-b7de-4a32-84f4-44f5e8dfeec7"
              }
            }
          ]
        }
      }
    ]

    collections = [
      {
        "data": {},
        "transaction_id": "01GNA82ZEK5ZJHBFEQEVG6B8BQ",
        "collection_id": "01GNA83QXB9VVNDD9RYQH3KGGP",
        "metadata": {
          "validation": false,
          "version": 2,
          "linked_collections": [
            {
              "collection_id": "01GNA83R23918BZEES7HX4QKDD",
              "label": "Request Collection"
            }
          ],
          "created_at": "2022-12-27T11:54:35.691615-05:00",
          "failure_reason": [
            "Service Connector failed. Connector flow failed. Response payload: {'Error': 'FailStateError', 'Cause': {'errorMessage': 'Email associated with another SSN', 'errorType': 'FailStateError', 'requestId': 'cd4df493-f41c-45a5-a925-a0c6976a78e5'}}"
          ],
          "invocation_status": "FAILED",
          "last_updated_at": "2022-12-27T11:54:52.441772-05:00",
          "partner_language": "asset-equifax",
          "partner_name": "equifax",
          "partner_status": "Email associated with another SSN",
          "partner_status_value": "FAILED",
          "product_name": "asset",
          "service_invocation": {
            "Language": {
              "input": {
                "from_language": "staircase-graph",
                "to_language": "asset-equifax",
                "status": "COMPLETED",
                "invocation_id": "01GNA83RHK46TYY3ZEXBJ147T3"
              }
            },
            "operation_name": "language_input",
            "Connector": {
              "status": "FAILED",
              "invocation_id": "b67d837d-9d08-48a1-8598-37c1b448ee73",
              "connector_flow_name": "asset"
            }
          },
          "staircase_language_version": 2
        }
      }
    ] */

    const more = transactions.length % 10 === 0;
    const cTransactions = transactionsResult;
    const cDetails = detailsResult;
    const cCollections = collectionResult;
    setCollectionResult([...cCollections, ...collections]);
    setDetailsResult([...cDetails, ...details]);
    setTransactionsResult([...cTransactions, ...transactions]);

    console.log("JF - Collection results:", collections)
    console.log("JF - Details results:", details)
    console.log("JF - Transaction results:", transactions)

    setHasMore(more);
    setLoadingMore(false);
  }

  async function getDetails(transaction) {
    let trRes = await getTransactionInfo(
      transaction.id,
      domain,
      apiKey,
      "asset"
    );
    return trRes;
  }

  async function getCollections(detail) {
    if (detail) {
      if (
        detail.response_payload.response &&
        detail.response_payload.response.referenceNumber
      ) {
        const referenceNumbers =
          detail.response_payload?.response?.referenceNumber.split("#");
        if (referenceNumbers.length >= 2) {
          const transactionId = referenceNumbers[0];
          const collectionId = referenceNumbers[1];
          return await getStaircaseCollection(
            domain,
            apiKey,
            transactionId,
            collectionId
          );
        } else {
          return { response_payload: { response: { status: "processing" } } };
        }
      }
    } else {
      return { response_payload: { response: { status: "processing" } } };
    }
  }

  async function loadMoreButtonClicked() {
    const nextPage = currentPage + 1;
    await loadTransactions(entityId, nextPage);
  }

  function getStatusColor(state) {
    console.log("getStatusColor", state);
    state = state.toLowerCase()
    if (state === "completed") {
      return "#1E9C0A";
    } else if (state === "failed") {
      return "#D5320F";
    } else {
      return "#5E84FF";
    }
  }

  function handleNewAssetVerificationClick(evt) {
    evt.preventDefault();
    history.push({
      pathname: "/asset"
    });
  }

  function handleGotoDetailsClick(data) {
    history.push({
      pathname: "/asset/result/single",
      state: {
        transactionRequests: [
          {
            transactionId: data.id,
          },
        ],
        domain,
        apiKey,
        verificationType: "Asset",
      },
    });
  }

  return (
    <>
      {loading === true ? (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={120}
          width={120}
          id="loader"
          style={{
            position: "absolute",
            left: "46%",
            top: "35%",
            height: "31px",
            width: "31px",
          }}
        />
      ) : (
        <div
          id="verification-result-container"
          className="pageContainer"
          style={{ marginBottom: "200px" }}
        >
          <div className="verificationHeaderHolder">
            <p id="verificationHeader" className="verificationContentHeader">
              Transaction History
            </p>
          </div>
          <div className="breadcrumbHolder">
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={handleNewAssetVerificationClick}
                href="/"
              >
                New Asset Verification
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Transaction History</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="transactionHistoryContainer">
            <Table>
              <thead>
                <tr>
                  {transactionHistoryHeaders.map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transactionsResult.map((item, index) => {
                  const borrowerType =
                    detailsResult[index].response_payload?.request?.options
                      .borrowerType;
                  const partnerName =
                    detailsResult[index].response_payload?.request?.options
                      .partner_name;
                  const borrowerName =
                    detailsResult[index].response_payload?.request?.loan
                      .applications[0][borrowerType].fullNameWithSuffix;
                  let referenceNumber = "-";
                  if (
                    collectionResult &&
                    collectionResult[index] &&
                    collectionResult[index].metadata &&
                    collectionResult[index].metadata.partner_reference_number
                  ) {
                    referenceNumber =
                      collectionResult[index].metadata.partner_reference_number;
                  }
                  let status = "Processing"
                  if (
                    collectionResult[index].metadata.partner_status_value
                  ) {
                    status = collectionResult[index].metadata.partner_status_value
                  }
                  if (
                    !collectionResult[index].metadata.partner_status_value &&
                    detailsResult &&
                    detailsResult[index] &&
                    detailsResult[index].response_payload &&
                    detailsResult[index].response_payload.response &&
                    detailsResult[index].response_payload.response.status
                  ) {
                    status = detailsResult[index].response_payload.response.status;
                  }

                  let partnerResponse = "-";
                  if (
                    collectionResult[index].metadata.partner_status
                  ) {
                    partnerResponse = collectionResult[index].metadata.partner_status
                  }
                  if (
                    !collectionResult[index].metadata.partner_status &&
                    detailsResult &&
                    detailsResult[index] &&
                    detailsResult[index].response_payload &&
                    detailsResult[index].response_payload.response &&
                    detailsResult[index].response_payload.response.partnerStatus
                  ) {
                    partnerResponse =
                      detailsResult[index].response_payload.response
                        .partnerStatus;
                  }
                  let rowItems = [];
                  rowItems.push(item.created.split("T")[0]);
                  rowItems.push(
                    item.product.name.toLowerCase().includes("voa") ||
                      item.product.name.toLowerCase().includes("asset")
                      ? "Asset Verification"
                      : item.product.name.replace("Test", "")
                  );
                  rowItems.push(borrowerName ? borrowerName : "-");
                  rowItems.push(
                    borrowerType ? toUpperFirstChar(borrowerType) : "-"
                  );
                  rowItems.push(
                    partnerName ? toUpperFirstChar(partnerName) : "-"
                  );
                  rowItems.push(referenceNumber);
                  rowItems.push(partnerResponse);
                  rowItems.push(status);

                  if (detailsResult[index].response_payload?.response?.errors) {
                    rowItems.push(
                      detailsResult[
                        index
                      ].response_payload.response.errors[0].description.replace(
                        "$.response_payload.response_str",
                        "Contact Staircase Support"
                      )
                    );
                  } else {
                    if (partnerResponse != "-" && status == "FAILED") {
                      rowItems.push(partnerResponse);
                    } else {
                      rowItems.push("-");
                    }
                  }

                  return (
                    <tr>
                      {rowItems.map((value, index) => {
                        if (index === 7) {
                          return (
                            <td key={index}>
                              <span
                                className="transactionStatusText"
                                style={{
                                  color: getStatusColor(value),
                                }}
                              >
                                {value.toUpperCase()}
                              </span>
                            </td>
                          );
                        } else {
                          return <td key={index}>{value}</td>;
                        }
                      })}
                      <td>
                        <span>
                          <Button
                            variant="link"
                            onClick={() => handleGotoDetailsClick(item)}
                          >
                            Go to details
                          </Button>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {hasMore && !loadingMore ? (
              <button id="loadMoreButton" onClick={loadMoreButtonClicked}>
                Load More
              </button>
            ) : (
              <div />
            )}
            {loadingMore === true ? (
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={24}
                width={24}
                id="loader"
                style={{
                  position: "static",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default TransactionListPage;
