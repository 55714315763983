const IS_LOCAL_ENV =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1';

// const DEV_DOMAIN = 'setup-dev.staircaseapi.com';

const API_PATH = 'setup-api';

function getApiBaseUrl() {
  console.log("GET NEW URL")
  let url = window.location.hostname;
  url = url.replace("localhost:3000", "bushnell-production.staircaseapi.com")
  return url.replace('setup-ui.', '');
}

export const ROUTER_BASE_NAME = IS_LOCAL_ENV ? '/' : '/setup/';

export const API_URL = IS_LOCAL_ENV
  ? API_PATH
  : `https://${getApiBaseUrl()}/${API_PATH}/`;
